export default {
    state: {
        pathToPageAfterConfirm: "",
        isLeavePageWithActiveFormBtnStatus: false,
    },
    actions: {
        updatePathToPageAfterConfirm({ commit }, path) {
            commit("changePathToPageAfterConfirm", path);
        },
        updateIsLeavePageWithActiveFormBtnStatus({ commit }, status) {
            commit("changeIsLeavePageWithActiveFormBtnStatus", status);
        },
        resetStateCleanStorageAfterSwitchBetweenPage({ commit }) {
            commit("changeIsLeavePageWithActiveFormBtnStatus", false);
            commit("changePathToPageAfterConfirm", "");
        },
        clearLifetimeCaseByIdStorage({ dispatch }) {
            dispatch("updateFormCaseByIdTabAllBtnStatus", false);
            dispatch("updateLifetimeCaseById", []);
            dispatch("clearLifetimeDiseaseData");
            dispatch("clearLifetimeMaterialData");
            dispatch("clearStorageRelatedWithAddNewLifetimeCase");
        },
        clearLifetimeCasesFilter({ dispatch }) {
            dispatch("updateLifetimeSelectOptionStageData", "");
            dispatch("updateLifetimeSelectOptionResponsibleData", "");
            dispatch("updateLifetimeCasesAssignMeButtonClickedStatus", false);
            dispatch("updateLifetimeCasesDataWithSort", [[], false]);
            dispatch("updateLifetimeCasesFilterTypeData", null);
            dispatch("updateLifetimeCasesInputSortDateFromData", "");
            dispatch("updateLifetimeCasesInputSortDateToData", "");
            dispatch("updateLifetimePage", 1);
        },
        clearLifetimeCasesStorage({ dispatch }) {
            dispatch("updateLifetimeCases", [[]]);
            dispatch(
                "updateLifetimeCasesOpenFiltersButtonClickedStatus",
                false
            );
            dispatch("updateLifetimeCasesSearchInputData", "");
            dispatch("updateLifetimeCasesInputSortDateFromData", "");
            dispatch("updateLifetimeCasesInputSortDateToData", "");
            dispatch("updateLifetimeCasesDataWithSort", [[], false]);
            dispatch("updateScrollArrowTopButtonStatus", false);
            dispatch("updateLifetimeCasesChecked", []);
        },
        clearSettingsLifetimeGeneral({ dispatch }) {
            dispatch("updateSettingsLifetimeGeneralForm", {});
            dispatch("updateFormSettingsLifetimeGeneralBtnStatus", false);
        },
        clearReferenceSelectedOrganizationDataState({ dispatch }) {
            dispatch("clearReferenceOrganizationData");
            dispatch("clearReferenceOrganizationDepartmentData");
            dispatch("clearReferenceOrganizationDepartmentDoctorData");
        },
        clearLifetimeCasesRegistrationInformationDataState({ dispatch }) {
            dispatch("clearLifetimeGeneralData");
        },
    },
    mutations: {
        changePathToPageAfterConfirm(state, path) {
            state.pathToPageAfterConfirm = path;
        },
        changeIsLeavePageWithActiveFormBtnStatus(state, status) {
            state.isLeavePageWithActiveFormBtnStatus = status;
        },
    },
    getters: {
        getPathToPageAfterConfirmation(state) {
            return state.pathToPageAfterConfirm;
        },
        getIsLeavePageWithActiveFormBtnStatus(state) {
            return state.isLeavePageWithActiveFormBtnStatus;
        },
    },
};
