import { lifetimeApi } from "@/api";

export default {
    state: {
        lifetimeCaseCassete: null,
        lifetimeCaseMicropraparation: null,
        lifetimeCaseMicropraparationArchiveType: null,
        lifetimeCaseNewFlasks: [],
        isNewFlasksLoading: false,
    },
    actions: {
        async addNewCasseteToFlask({ dispatch }, flaskId) {
            await dispatch("getAccessToken").then((token) => {
                if (token === undefined) {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    return;
                }
                dispatch("fetchNewLifetimeCaseCassete", [token, flaskId]);
            });
        },
        async addNewMicropreparationToCassette({ dispatch }, [flaskId, cassetteId]) {
            await dispatch("getAccessToken").then((token) => {
                if (token === undefined) {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    return;
                }
                dispatch("fetchNewLifetimeCaseMicropreparation", [token, flaskId, cassetteId]);
            });
        },
        async putLifetimeCaseFlasks({ dispatch }, newFlasks) {
            await dispatch("getAccessToken").then((token) => {
                if (token === undefined) {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    return;
                }
                dispatch("fetchPutLifetimeCaseFlasks", [token, newFlasks]);
            });
        },
        async fetchNewLifetimeCaseCassete({ dispatch }, [token, flaskId]) {
            try {
                const newLifetimeCaseCassete = await lifetimeApi.addLifetimeCasesMacroDescriptionCassette(flaskId, token);
                dispatch("updateLifetimeCaseCassete", [newLifetimeCaseCassete, flaskId]);
            } catch (e) {
                console.error(e);
            }
        },
        async fetchNewLifetimeCaseMicropreparation({ dispatch }, [token, flaskId, cassetteId]) {
            try {
                const newLifetimeCaseMicropraparation = await lifetimeApi.addLifetimeCasesMacroDescriptionMicropreparation(cassetteId, token);
                dispatch("updateLifetimeCaseMicropraparation", [flaskId, cassetteId, newLifetimeCaseMicropraparation]);
            } catch (e) {
                console.error(e);
            }
        },
        async deleteMacroscopyCassette({ dispatch }, [flaskId, cassetteId]) {
            await dispatch("getAccessToken").then((token) => {
                if (token === undefined) {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    return;
                }
                dispatch("fetchDeleteMacroscopyCassette", [token, flaskId, cassetteId]);
            });
        },
        async fetchDeleteMacroscopyCassette({ dispatch }, [token, flaskId, cassetteId]) {
            try {
                await lifetimeApi.deleteLifetimeCasesMacroDescriptionCassette(cassetteId, token);
                dispatch("deleteLifetimeCaseCassete", [flaskId, cassetteId]);
            } catch (e) {
                console.error(e);
            }
        },
        async deleteMacroscopyMicropreparation({ dispatch }, [flaskId, cassetteId, micropreparationId]) {
            await dispatch("getAccessToken").then((token) => {
                if (token === undefined) {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    return;
                }
                dispatch("fetchDeleteMacroscopyMicropreparation", [token, flaskId, cassetteId, micropreparationId]);
            });
        },
        async fetchDeleteMacroscopyMicropreparation({ dispatch }, [token, flaskId, cassetteId, micropreparationId]) {
            try {
                await lifetimeApi.deleteLifetimeCasesMacroDescriptionMicropreparation(micropreparationId, token);
                dispatch("deleteLifetimeCaseCasseteMicropreparation", [flaskId, cassetteId, micropreparationId]);
            } catch (e) {
                console.error(e);
            }
        },
        async fetchPutLifetimeCaseFlasks({ commit, dispatch }, [token, newFlasks]) {
            commit("changeIsNewFlasksLoading", true);
            try {
                const newFlasksValue = await lifetimeApi.editLifetimeCasesMacroDescriptionMicropreparation(newFlasks, token);
                if (newFlasksValue) {
                    commit("changeIsNewFlasksLoading", false);
                    dispatch("updateLifetimeCaseFlasks", newFlasksValue);
                }
            } catch (e) {
                console.error(e);
                commit("changeIsNewFlasksLoading", false);
            }
        },
        updateLifetimeCaseCassete({ commit }, [newLifetimeCaseCassete, flaskId]) {
            commit("addCassetteToLifetimeCaseByIdViewing", [newLifetimeCaseCassete, flaskId], { root: true });
        },
        deleteLifetimeCaseCassete({ commit }, [flaskId, cassetteId]) {
            commit("deleteCassetteFromLifetimeCaseByIdViewing", [flaskId, cassetteId], { root: true });
        },
        deleteLifetimeCaseCasseteMicropreparation({ commit }, [flaskId, cassetteId, micropreparationId]) {
            commit("deleteCassetteMicropreparationFromLifetimeCaseByIdViewing", [flaskId, cassetteId, micropreparationId], { root: true });
        },
        updateLifetimeCaseMicropraparation({ commit }, [flaskId, cassetteId, newLifetimeCaseMicropraparation]) {
            commit("addMicropraparationToLifetimeCaseCassette", [flaskId, cassetteId.cassette_id, newLifetimeCaseMicropraparation], { root: true });
        },
        updateLifetimeCaseFlaskDescription({ commit }, [flaskId, newFlaskDescription]) {
            commit("changeLifetimeCaseFlaskDescription", [flaskId, newFlaskDescription], { root: true });
        },
        updateLifetimeCaseCassetteAdditionalFicsation({ commit }, [flaskId, cassetteId, status]) {
            commit("changeLifetimeCaseCassetteAdditionalFicsation", [flaskId, cassetteId, status], { root: true });
        },
        updateLifetimeCaseCassetteDecalcification({ commit }, [flaskId, cassetteId, status]) {
            commit("changeLifetimeCaseCassetteDecalcification", [flaskId, cassetteId, status], { root: true });
        },
        updateLifetimeCaseCassetteWiring({ commit }, [flaskId, cassetteId, status]) {
            commit("changeLifetimeCaseCassetteWiring", [flaskId, cassetteId, status], { root: true });
        },
        updateLifetimeCaseCassetteDescription({ commit }, [flaskId, cassetteId, cassetteDescription]) {
            commit("changeLifetimeCaseCassetteDescription", [flaskId, cassetteId, cassetteDescription], { root: true });
        },
        updateLifetimeCaseCassettePiecesCount({ commit }, [flaskId, cassetteId, piecesCount]) {
            commit("changeLifetimeCaseCassettePiecesCount", [flaskId, cassetteId, piecesCount], { root: true });
        },
        updateLifetimeCaseMicropreparationColoringMethod({ commit }, [flaskId, cassetteId, micropreparationId, coloringMethod]) {
            commit("changeLifetimeCaseMicropreparationColoringMethod", [flaskId, cassetteId, micropreparationId, coloringMethod], { root: true });
        },
        updateLifetimeCaseMicropreparationColoringList({ commit }, [flaskId, cassetteId, micropreparationId, coloring]) {
            commit("changeLifetimeCaseMicropreparationColoringList", [flaskId, cassetteId, micropreparationId, coloring], { root: true });
        },
        updateLifetimeCaseMicropreparationArchiveType({ commit }, archiveType) {
            commit("changeLifetimeCaseMicropreparationArchiveType", archiveType);
        },
        updateLifetimeCaseFlasks({ commit }, newFlasksValue) {
            commit("changeLifetimeCaseFlasks", newFlasksValue);
        },
    },
    mutations: {
        changeLifetimeCaseCassete(state, newLifetimeCaseCassete) {
            state.lifetimeCaseCassete = newLifetimeCaseCassete;
        },
        changeLifetimeCaseMicropreparation(state, newLifetimeCaseMicropraparation) {
            state.lifetimeCaseCassete = newLifetimeCaseMicropraparation;
        },
        changeLifetimeCaseMicropreparationArchiveType(state, archiveType) {
            state.lifetimeCaseMicropraparationArchiveType = archiveType;
        },
        changeLifetimeCaseFlasks(state, flasks) {
            state.lifetimeCaseNewFlasks = flasks;
        },
        changeIsNewFlasksLoading(state, conditions) {
            state.isNewFlasksLoading = conditions;
        }
    },
    getters: {
        getLifetimeCaseCassete(state) {
            return state.lifetimeCaseCassete;
        },
        getLifetimeCaseMicropraparation(state) {
            return state.lifetimeCaseMicropraparation;
        },
        getLifetimeCaseMicropreparationArchiveType(state) {
            return state.lifetimeCaseMicropraparationArchiveType;
        },
        getLifetimeCaseFlasks(state) {
            return state.lifetimeCaseNewFlasks;
        },
        getIsNewFlasksLoading(state) {
            return state.isNewFlasksLoading;
        },
    }
}