export default {
    state: {
        currentUserPermissions: null,
    },
    actions: {
        defineUserPermissions({ commit }, userPermissionsData) {
            const currentPositionId = JSON.parse(localStorage.getItem('employeePositionId'));
            const currentUserPermissionsByPositionId = userPermissionsData.find((position) => position.id === currentPositionId);
            commit("changeCurrentUserPermissions", currentUserPermissionsByPositionId.position_permissions);
        },
        clearUserPermissions({ commit }) {
            commit("changeCurrentUserPermissions", null);
        }
    },
    mutations: {
        changeCurrentUserPermissions(state, userPermissionsData) {
            if (userPermissionsData === null) {
                state.currentUserPermissions = null;
                return;
            }
            if (userPermissionsData.length === 0) {
                state.currentUserPermissions = [];
                return;
            }
            state.currentUserPermissions = {};
            userPermissionsData.forEach((permission) => {
                state.currentUserPermissions[permission.alias] = permission.is_active;
            });

            state.currentUserPermissions.mortem = true; //TODO УДАЛИТЬ ЗАГЛУШКУ!!!
        }
    },
    getters: {
        getCurrentUserPermissions(state) {
            return state.currentUserPermissions;
        }
    }
}
