import { api } from '@/api'

export default {
    state: {
        patientInsuranceDocuments: [],
        selectedInsuranceDocData: null,
        isPatientInsuranceDocumentsLoading: false,
    },
    actions: {
        async fetchPatientInsuranceDocuments({ dispatch, commit }) {
            try {
                const token = await dispatch('getAccessToken')
                if (token) {
                    const documents = await api.getPatientInsuranceDocuments(
                        token
                    )
                    commit('changePatientInsuranceDocuments', documents)
                } else {
                    dispatch('getRedirectAuthAfterInvalidRefreshTokenAction')
                    return
                }
            } catch (error) {
                console.log(error)
            }
        },
        async addPatientInsuranceDocuments({ dispatch, commit }, data) {
            commit('updateIsPatientInsuranceDocumentsLoading', true)
            try {
                const token = await dispatch('getAccessToken')
                if (token) {
                    await api
                        .postPatientInsuranceDocuments(data, token)
                        .then(() => {
                            dispatch(
                                'fetchSettingsReferencePatientById',
                                data.patient
                            )
                        })
                    commit('updateIsPatientInsuranceDocumentsLoading', false)
                } else {
                    commit('updateIsPatientInsuranceDocumentsLoading', false)
                    dispatch('getRedirectAuthAfterInvalidRefreshTokenAction')
                    return
                }
            } catch (error) {
                commit('updateIsPatientInsuranceDocumentsLoading', false)
                console.log(error)
            }
        },
        async editPatientInsuranceDocuments({ dispatch, commit }, [data, id]) {
            commit('updateIsPatientInsuranceDocumentsLoading', true)
            try {
                const token = await dispatch('getAccessToken')
                if (token) {
                    await api
                        .patchPatientInsuranceDocuments(id, data, token)
                        .then(() => {
                            dispatch(
                                'fetchSettingsReferencePatientById',
                                data.patient
                            )
                        })
                    commit('updateIsPatientInsuranceDocumentsLoading', false)
                } else {
                    commit('updateIsPatientInsuranceDocumentsLoading', false)
                    dispatch('getRedirectAuthAfterInvalidRefreshTokenAction')
                    return
                }
            } catch (error) {
                commit('updateIsPatientInsuranceDocumentsLoading', false)
                console.log(error)
            }
        },
        async deletePatientInsuranceDocuments({ dispatch }, [patientId, id]) {
            try {
                const token = await dispatch('getAccessToken')
                if (token) {
                    await api
                        .deletePatientInsuranceDocuments(id, token)
                        .then(() => {
                            dispatch(
                                'fetchSettingsReferencePatientById',
                                patientId
                            )
                        })
                } else {
                    dispatch('getRedirectAuthAfterInvalidRefreshTokenAction')
                    return
                }
            } catch (error) {
                console.log(error)
            }
        },
        updateSelectedInsuranceDocData({ commit }, data) {
            commit('changeSelectedInsuranceDocData', data)
        },
    },
    mutations: {
        changeSelectedInsuranceDocData(state, data) {
            state.selectedInsuranceDocData = {
                ...data,
                type:
                    data?.type === 'oms'
                        ? { id: 'oms', title: 'ОМС' }
                        : { id: 'dms', title: 'ДМС' },
            }
        },
        updateIsPatientInsuranceDocumentsLoading(state, status) {
            state.isPatientInsuranceDocumentsLoading = status
        },
    },
    getters: {
        getPatientInsuranceDocuments(state) {
            return state.patientInsuranceDocuments
        },
        getSelectedInsuranceDocData(state) {
            return state.selectedInsuranceDocData
        },
        getIsPatientInsuranceDocumentsLoading(state) {
            return state.isPatientInsuranceDocumentsLoading
        },
    },
}
