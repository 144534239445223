export const referencePatientsFields = {
    data() {
        return {
            referencePatientsTableDescriptionData: [
                { field: "full_name", header: "ФИО", columnWidth: "234px"},
                { field: "ipa", header: "СНИЛС", columnWidth: "234px"},
                { field: "gender", header: "Пол", columnWidth: "234px"},
                { field: "phone", header: "Номер телефона", columnWidth: "234px"},
                { field: "birthday", header: "Дата рождения", columnWidth: "234px"},
            ],
        }
    },
}