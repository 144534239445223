import { api } from "@/api";

export default {
    state: {
        patientLegalGuardian: [],
        selectedLegalGuardian: null,
        isLegalGuardianLoading: false,
    },
    actions: {
        async fetchPatientLegalGuardian({ dispatch }) {
            try {
                const token = await dispatch("getAccessToken");
                if (token) {
                    await api.getPatientLegalGuardian(token);
                } else {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    return;
                }
            } catch (error) {
                console.log(error);
            }
        },
        async addPatientLegalGuardian({ dispatch, commit, rootGetters }, data) {
            commit("updateLegalGuardianLoader", true);
            try {
                const token = await dispatch("getAccessToken");
                if (token) {
                    await dispatch("addSettingsReferencePatients", data);
                    const legalGuardianData = {
                        patient: rootGetters.getNewSettingsReferencePatient,
                        legal_guardian_nsi: data?.legal_guardian_nsi,
                    };
                    await api
                        .postPatientLegalGuardian(legalGuardianData, token)
                        .then((legalGuardian) => {
                            dispatch("addPatientLeagalGuardian", legalGuardian);
                            const dataToSend = {
                                id: rootGetters.getSettingsReferencePatientById
                                    ?.id,
                                ...(rootGetters.getSettingsReferencePatientById
                                    ?.ipa && {
                                    ipa: rootGetters
                                        .getSettingsReferencePatientById?.ipa,
                                }),
                                previous_cases:
                                    rootGetters.getSettingsReferencePatientById
                                        ?.previous_cases,
                                legal_guardian:
                                    rootGetters.getSettingsReferencePatientById?.legal_guardian.map(
                                        (item) => item.id
                                    ),
                            };
                            dispatch("editSettingsReferencePatients", [
                                dataToSend,
                                rootGetters.getSettingsReferencePatientById?.id,
                            ]);
                        });
                    commit("updateLegalGuardianLoader", false);
                } else {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    commit("updateLegalGuardianLoader", false);
                    return;
                }
            } catch (error) {
                console.log(error);
            }
        },
        async editPatientLegalGuardian(
            { dispatch, commit },
            [data, id, patientId]
        ) {
            commit("updateLegalGuardianLoader", true);
            try {
                const token = await dispatch("getAccessToken");
                if (token) {
                    await api.patchReferencePatientInfo(data, patientId, token);
                    const legalGuardianData = {
                        patient: data?.patient?.id,
                        legal_guardian_nsi: data.legal_guardian_nsi,
                    };
                    await api.patchPatientLegalGuardian(
                        id,
                        legalGuardianData,
                        token
                    );
                    commit("updateLegalGuardianLoader", false);
                } else {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    commit("updateLegalGuardianLoader", false);
                    return;
                }
            } catch (error) {
                console.log(error);
            }
        },
        async deletePatientLegalGuardian({ dispatch }, [patientId, id]) {
            try {
                const token = await dispatch("getAccessToken");
                if (token) {
                    await api.deletePatientLegalGuardian(id, token).then(() => {
                        dispatch(
                            "fetchSettingsReferencePatientById",
                            patientId
                        );
                    });
                } else {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    return;
                }
            } catch (error) {
                console.log(error);
            }
        },
        async addPatientLegalJuridicalGuardian(
            { dispatch, commit, rootGetters },
            data
        ) {
            commit("updateLegalGuardianLoader", true);
            try {
                const token = await dispatch("getAccessToken");
                if (token) {
                    const organizationData =
                        await api.postSettingsReferenceOrganization(
                            data,
                            token
                        );
                    const legalGuardianData = {
                        organization: organizationData.id,
                    };
                    await api
                        .postPatientLegalGuardian(legalGuardianData, token)
                        .then((legalGuardian) => {
                            dispatch("addPatientLeagalGuardian", legalGuardian);
                            const dataToSend = {
                                id: rootGetters.getSettingsReferencePatientById
                                    ?.id,
                                ...(rootGetters.getSettingsReferencePatientById
                                    ?.ipa && {
                                    ipa: rootGetters
                                        .getSettingsReferencePatientById?.ipa,
                                }),
                                previous_cases:
                                    rootGetters.getSettingsReferencePatientById
                                        ?.previous_cases,
                                legal_guardian:
                                    rootGetters.getSettingsReferencePatientById?.legal_guardian.map(
                                        (item) => item.id
                                    ),
                            };
                            dispatch("editSettingsReferencePatients", [
                                dataToSend,
                                dataToSend.id,
                            ]);
                            commit("updateLegalGuardianLoader", false);
                        });
                } else {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    commit("updateLegalGuardianLoader", false);
                    return;
                }
            } catch (error) {
                console.log(error);
            }
        },
        async editPatientLegalJuridicalGuardian(
            { dispatch, commit },
            [data, id]
        ) {
            commit("updateLegalGuardianLoader", true);
            try {
                const token = await dispatch("getAccessToken");
                if (token) {
                    await api.patchSettingsReferenceOrganization(
                        data,
                        data.id,
                        token
                    );
                    const legalGuardianData = {
                        organization: data.id,
                    };
                    await api.patchPatientLegalGuardian(
                        id,
                        legalGuardianData,
                        token
                    );
                    commit("updateLegalGuardianLoader", false);
                } else {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    commit("updateLegalGuardianLoader", false);
                    return;
                }
            } catch (error) {
                console.log(error);
            }
        },
        updateSelectedGuardian({ commit }, data) {
            commit("changeSelectedGuardian", data);
        },
        updateLegalGuardianAddress({ commit }, address) {
            commit("changeLegalGuardianAddress", address);
        },
    },
    mutations: {
        changeSelectedGuardian(state, data) {
            state.selectedLegalGuardian = data;
        },
        changeLegalGuardianAddress(state, address) {
            state.selectedLegalGuardian?.patient?.address.push(address);
        },
        updateLegalGuardianLoader(state, status) {
            state.isLegalGuardianLoading = status;
        },
    },
    getters: {
        getSelectedGuardian(state) {
            return state.selectedLegalGuardian;
        },
        getLegalGuardianLoaderStatus(state) {
            return state.isLegalGuardianLoading;
        },
    },
};
