import { redirectAuth } from '@/helpers/redirect'
import store from '@/store'

function getCookieExpirationDate(days) {
    const date = new Date()
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
    return date.toUTCString()
}

export const executeRequestToServer = async (
    url,
    method,
    token,
    data = null
) => {
    const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        accept: 'application/json',
    }

    const body = data ? JSON.stringify(data) : undefined

    const initFetchObject = {
        method,
        headers:
            method === 'GET' || method === 'DELETE'
                ? { Authorization: `Bearer ${token}` }
                : headers,
        body,
    }

    try {
        const res = await fetch(url, initFetchObject)

        if (!res.ok) {
            let errorMessage = res.statusText
            try {
                const errorBody = await res.json()
                if (errorBody.errors && Array.isArray(errorBody.errors)) {
                    errorMessage = errorBody.errors
                        .map((error) => error.detail)
                        .join(', ')
                }
            } catch (parseError) {
                console.error(
                    'Error parsing error response as JSON:',
                    parseError
                )
            }

            const errorObj = { code: res.status, message: errorMessage }

            if (res.status === 401) {
                await store.dispatch('updateAccessToken') // Попытка обновления токена
                const newToken = store.getters.getToken
                if (newToken) {
                    // Повторный запрос с обновленным токеном
                    return executeRequestToServer(url, method, newToken, data)
                } else {
                    store.dispatch('addToastMessage', errorObj)
                    redirectAuth()
                    throw errorObj // Прокидываем ошибку в блок catch
                }
            } else {
                console.log(res)
                store.dispatch('addToastMessage', errorObj)
                throw errorObj // Прокидываем ошибку в блок catch
            }

        } else {
            const resText = (await res.text()) || null
            let resData = null

            try {
                if (resText) {
                    resData = JSON.parse(resText)
                } else {
                    resData = res
                }
            } catch (error) {
                console.error('Error parsing response as JSON:', error)
                throw error // Прокидываем ошибку в блок catch
            }

            return resData
        }
    } catch (error) {
        console.error(error)
        const errorMessage = error.message || error
        const errorObj = { code: error.code || 500, message: errorMessage }
        store.dispatch('addToastMessage', errorObj)
        throw errorObj // Прокидываем ошибку дальше
    }
}

//TODO Метод executeDeleteRequestToServer требует доработки, нужно использовать метод executeRequestToServer с возможностью передавать body для DELETE
export const executeDeleteRequestToServer = async (
    url,
    method,
    token,
    data = null
) => {
    const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        accept: 'application/json',
    }

    const body = data ? JSON.stringify(data) : undefined

    const initFetchObject = {
        method,
        headers,
        body,
    }

    try {
        const res = await fetch(url, initFetchObject)

        if (!res.ok) {
            let errorMessage = res.statusText
            try {
                const errorBody = await res.json()
                if (errorBody.errors && Array.isArray(errorBody.errors)) {
                    errorMessage = errorBody.errors
                        .map((error) => error.detail)
                        .join(', ')
                }
            } catch (parseError) {
                console.error(
                    'Error parsing error response as JSON:',
                    parseError
                )
            }

            const errorObj = { code: res.status, message: errorMessage }

            if (res.status === 401) {
                await store.dispatch('updateAccessToken') // Попытка обновления токена
                const newToken = store.getters.getToken
                if (newToken) {
                    // Повторный запрос с обновленным токеном
                    return executeRequestToServer(url, method, newToken, data)
                } else {
                    store.dispatch('addToastMessage', errorObj)
                    redirectAuth()
                    throw errorObj // Прокидываем ошибку в блок catch
                }
            } else {
                console.log(res)
                store.dispatch('addToastMessage', errorObj)
                throw errorObj // Прокидываем ошибку в блок catch
            }

        } else {
            const resText = (await res.text()) || null
            let resData = null

            try {
                if (resText) {
                    resData = JSON.parse(resText)
                } else {
                    resData = res
                }
            } catch (error) {
                console.error('Error parsing response as JSON:', error)
                throw error // Прокидываем ошибку в блок catch
            }

            return resData
        }
    } catch (error) {
        console.error(error)
        const errorMessage = error.message || error
        const errorObj = { code: error.code || 500, message: errorMessage }
        store.dispatch('addToastMessage', errorObj)
        throw errorObj // Прокидываем ошибку дальше
    }
}

export const executePdfRequestToServer = async (
    url,
    method,
    token,
    data = null
) => {
    const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        accept: 'application/json',
    }

    const body = data ? JSON.stringify(data) : undefined

    const initFetchObject = {
        method,
        headers:
            method === 'GET' || method === 'DELETE'
                ? { Authorization: `Bearer ${token}` }
                : headers,
        body,
    }

    try {
        const res = await fetch(url, initFetchObject)
        if (!res.ok) {
            let errorMessage = res.statusText
            try {
                const errorBody = await res.json()
                if (errorBody.errors && Array.isArray(errorBody.errors)) {
                    errorMessage = errorBody.errors
                        .map((error) => error.detail)
                        .join(', ')
                }
            } catch (parseError) {
                console.error(
                    'Error parsing error response as JSON:',
                    parseError
                )
            }

            const errorObj = { code: res.status, message: errorMessage }

            if (res.status === 401) {
                await store.dispatch('updateAccessToken')
                const newToken = store.getters.getToken
                if (newToken) {
                    return executePdfRequestToServer(
                        url,
                        method,
                        newToken,
                        data
                    )
                } else {
                    store.dispatch('addToastMessage', errorObj)
                    redirectAuth()
                    throw errorObj
                }
            } else {
                store.dispatch('addToastMessage', errorObj)
                throw errorObj
            }
        } else {
            console.log(res)
            return await res.blob()
        }
    } catch (error) {
        const errorMessage = error.message || error
        const errorObj = { code: error.code || 500, message: errorMessage }
        console.error(error)
        store.dispatch('addToastMessage', errorObj)
        throw errorObj
    }
}

export const executeWSConnectionToServer = async (url) => {
    try {
        return new WebSocket(url)
    } catch (error) {
        const errorMessage = error.message || error
        const errorObj = { code: error.code || 500, message: errorMessage }
        console.error(error)
        store.dispatch('addToastMessage', errorObj)
        throw errorObj
    }
}

export const executeGetAuth = async (url, authData) => {
    try {
        const res = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                accept: 'application/json',
            },
            body: JSON.stringify(authData),
        })

        if (!res.ok) {
            const resText = await res.text()
            const errorBody = resText ? JSON.parse(resText) : null
            const errorMessage = errorBody ? errorBody.message : res.statusText
            const errorObj = { code: res.status, message: errorMessage }
            localStorage.removeItem('accessTokenData')
            document.cookie = `refreshTokenData=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/;`
            console.log(errorBody)
            console.log('Error status =', res.status)
            store.dispatch('addToastMessage', errorObj)
            return res.status
        } else {
            const resText = (await res.text()) || null
            let resData = null

            if (resText) {
                resData = JSON.parse(resText)
            } else {
                resData = res
            }

            const tokens = resText ? JSON.parse(resText) : null

            const accessData = {
                access: tokens.access,
                access_lifetime: tokens.access_lifetime,
            }
            const refreshData = {
                refresh: tokens.refresh,
            }

            const cookieValue = encodeURIComponent(JSON.stringify(refreshData))

            document.cookie = `refreshTokenData=${cookieValue}; path=/; expires=${getCookieExpirationDate(
                2
            )}`

            localStorage.setItem('accessTokenData', JSON.stringify(accessData))

            return resData
        }
    } catch (error) {
        const errorMessage = error.message || error
        const errorObj = { code: error.code || 500, message: errorMessage }
        console.error(error)
        store.dispatch('addToastMessage', errorObj)
        throw errorObj
    }
}

export const executeGetRefreshToken = async (url) => {
    try {
        const cookieValue = document.cookie.replace(
            /(?:(?:^|.*;\s*)refreshTokenData\s*=\s*([^;]*).*$)|^.*$/,
            '$1'
        )
        const decodedCookieValue = decodeURIComponent(cookieValue)
        const refreshToken = JSON.parse(decodedCookieValue)?.refresh

        if (!refreshToken) {
            const errorMessage = 'Refresh token not found in Cookie'
            const errorObj = { code: 401, message: errorMessage }
            console.error(errorMessage)
            redirectAuth()
            store.dispatch('addToastMessage', errorObj)
            return null
        }

        const res = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                accept: 'application/json',
            },
            body: JSON.stringify({ refresh: refreshToken }),
        })

        if (!res.ok) {
            const errorMessage = `Error response: ${res.status} ${res.statusText}`
            const errorObj = { code: res.status, message: errorMessage }
            console.error(errorMessage)
            store.dispatch('addToastMessage', errorObj)
            return res.status
        }

        const contentType = res.headers.get('Content-Type')
        if (!contentType || !contentType.includes('application/json')) {
            const errorMessage = `Invalid Content-Type: ${contentType}`
            const errorObj = { code: 500, message: errorMessage }
            console.error(errorMessage)
            store.dispatch('addToastMessage', errorObj)
            return null
        }

        const resText = await res.text()

        if (!resText) {
            const errorMessage = 'Empty response text'
            const errorObj = { code: 500, message: errorMessage }
            console.error(errorMessage)
            store.dispatch('addToastMessage', errorObj)
            return null
        }

        const tokens = JSON.parse(resText)
        localStorage.setItem('accessTokenData', JSON.stringify(tokens))
        console.log('accessTokenData успешно обновлен в localStorage', tokens)

        return tokens.access
    } catch (error) {
        const errorMessage = error.message || error
        const errorObj = { code: error.code || 500, message: errorMessage }
        console.error(errorMessage)
        redirectAuth()
        store.dispatch('addToastMessage', errorObj)
        throw errorObj
    }
}

export const executePostRequestToServer = async (url, token, data) => {
    try {
        const res = await fetch(url, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
                accept: 'application/json',
            },
            body: data,
        })

        if (!res.ok) {
            let errorMessage = res.statusText
            try {
                const errorBody = await res.json()
                if (errorBody.errors && Array.isArray(errorBody.errors)) {
                    errorMessage = errorBody.errors
                        .map((error) => error.detail)
                        .join(', ')
                }
            } catch (parseError) {
                console.error(
                    'Error parsing error response as JSON:',
                    parseError
                )
            }

            const errorObj = { code: res.status, message: errorMessage }

            if (res.status === 401) {
                await store.dispatch('updateAccessToken') // Попытка обновления токена
                const newToken = store.getters.getToken
                return executePostRequestToServer(url, newToken, data)
            } else {
                store.dispatch('addToastMessage', errorObj)
                return res.status
            }
        }

        const resText = await res.text()
        const resData = resText ? JSON.parse(resText) : null
        return resData
    } catch (error) {
        const errorMessage = error.message || error
        const errorObj = { code: error.code || 500, message: errorMessage }
        console.error(error)
        store.dispatch('addToastMessage', errorObj)
        throw errorObj
    }
}

export const executePostFormDataRequestToServer = async (url, token, data) => {
    try {
        const res = await fetch(url, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body: data,
        })

        if (!res.ok) {
            let errorMessage = res.statusText
            try {
                const errorBody = await res.json()
                if (errorBody.errors && Array.isArray(errorBody.errors)) {
                    errorMessage = errorBody.errors
                        .map((error) => error.detail)
                        .join(', ')
                }
            } catch (parseError) {
                console.error(
                    'Error parsing error response as JSON:',
                    parseError
                )
            }

            const errorObj = { code: res.status, message: errorMessage }

            if (res.status === 401) {
                await store.dispatch('updateAccessToken')
                const newToken = store.getters.getToken
                return executePostFormDataRequestToServer(url, newToken, data)
            } else {
                store.dispatch('addToastMessage', errorObj)
                throw new Error(`Request failed with status ${res.status}`)
            }
        }

        const resText = await res.text()
        const resData = resText ? JSON.parse(resText) : null
        return resData
    } catch (error) {
        const errorMessage = error.message || error
        const errorObj = { code: error.code || 500, message: errorMessage }
        console.error(error)
        store.dispatch('addToastMessage', errorObj)
        throw errorObj
    }
}
