<template>
    <Dialog
        v-model:visible="isPrintDialogVisibility"
        modal
        header="Header"
        class="dialog"
        :pt="{
            headerIcons: {class: 'close__button_icon'}
        }"
    >
        <template #header>
            <div class="dialog__header">
                <span>Очередь печати</span>
            </div>
        </template>
        <div class="print__dialog_body">
            <AppDataTable
                :tableData="getLifetimePrintQueue"
                :tableSlotStructure="printQueueTableData"
                tableScrollHeight="calc(100vh - 150px)"
            >
                <template #created_at="{data}">
                    <p :key="data" style="margin-left: 12px;">
                        {{ changeTimezoneDateTimeFormat(data.created_at) }}
                    </p>
                </template>
                <template #document_type_label="{data}">
                    <div style="display: flex;">
                        <a
                            v-if="data.url"
                            :href="data.url"
                            class="main__footer_text"
                            style="padding-left: 12px;"
                        >
                            {{ data.document_type_label }}
                        </a>
                        <p v-else style="padding-left: 12px;">
                            {{ data.document_type_label }}
                        </p>
                        <p v-if="data.lifecase_count" style="margin-left: 8px;">
                            {{ `- ${data.lifecase_count} шт.` }}
                        </p>
                    </div>
                </template>
                <template #status="{data}">
                    <div
                        v-if="data.status === 'PROGRESS'"
                        style="padding-right: 20px"
                    >
                        <ProgressBar
                            :value="data?.progress"
                            :showValue="false"
                            :pt="{
                                root: {class: 'progress__bar_line'},
                                value:{class: 'progress__bar_value'}
                            }"
                        />
                    </div>
                    <p v-else :key="data" style="padding-left: 12px;">
                        {{ getTaskStatus(data.status) }}
                    </p>
                </template>
            </AppDataTable>
        </div>
        <template #footer>
            <Button
                class="button__purple_border"
                :disabled="getLifetimePrintQueue.length === 0"
                @click="clearPrintQueue"
            >
                <i
                    class="button__icon icon__clear_button"
                    v-html="getIconTemplate('icon-clear')"
                ></i>
                <p>Очистить</p>
            </Button>
            <Button
                class="button__purple"
                label="Закрыть"
                @click="isPrintDialogVisibility = false"
            />
        </template>
    </Dialog>
    <div class="main__footer">
        <div class="main__footer_left">
            <p
                v-if="$route.name === 'lifetimeCases' || $route.name === 'lifetimeCasesSamplePreparation'"
                class="main__footer_left-text"
            >
                Количество случаев в работе: {{ $route.name === 'lifetimeCases' ? allLifetimeCases?.count || 0 : getSamplePreparationTotalCount }}
            </p>
            <p
                v-if="$route.name === 'lifetimeCases' || $route.name === 'lifetimeCasesSamplePreparation'"
                class="main__footer_left-text"
            >
                Отмечено случаев: {{ $route.name === 'lifetimeCases' ? getLifetimeCasesChecked.length : (getSamplePreparationSelectedCassette.length || getSamplePreparationSelectedMicro.length) }}
            </p>
        </div>
        <div class="main__footer_right">
            <!-- <div class="main__footer_error">
                <span class="main__footer_error-text">
                    Ошибка передачи в Documeds
                </span>
                <div class="main__footer_error-count">
                    3
                </div>
            </div> -->
            <div
                class="main__footer_error"
                @click.stop="isPrintDialogVisibility = true"
            >
                <span class="main__footer_text">
                    Готово к печати
                </span>
                <div class="main__footer_count">
                    {{ getLifetimePrintQueue.length }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { fullName, changeDateFormatConst, icons } from "@/mixins";
import AppDataTable from "@/components/AppDataTable";
import ProgressBar from 'primevue/progressbar';

export default {
    name: "AppFooter",
    mixins: [fullName, changeDateFormatConst, icons],
    data() {
        return {
            isPrintDialogVisibility: false,
            printQueueTableData: [
                { field: "created_at", header: "Дата и время", columnWidth: "143px"},
                { field: "document_type_label", header: "Объект печати", columnWidth: "auto"},
                { field: "status", header: "Статус", columnWidth: "196px"},
            ],
        }
    },
    components: {
        AppDataTable,
        ProgressBar
    },
    computed: {
        ...mapGetters([
            "getLifetimeCasesChecked",
            "allLifetimeCases",
            "getProfileData",
            "getSamplePreparationTotalCount",
            "getSamplePreparationSelectedCassette",
            "getSamplePreparationSelectedMicro",
            "getLifetimePrintQueue",
            "getPrintWsConnection"
        ]),
        getTaskStatus() {
            return (status) => {
                switch (status) {
                    case "SUCCESS":
                        return "Готов";
                    case "PROGRESS":
                        return "Выполняется";
                    case "PENDING":
                        return "Ожидание...";
                    default:
                        return "";
                }
            }
        },
    },
    methods: {
        ...mapActions([
            "connectToDocumentWS",
            "disconnectFromDocumentWS",
            "clearPrintQueueRequest",
        ]),
        clearPrintQueue() {
            this.clearPrintQueueRequest();
        }
    },
    created() {
        if (this.getPrintWsConnection) {
            return;
        }
        this.connectToDocumentWS();
    },
    unmounted() {
        this.disconnectFromDocumentWS();
    }
};
</script>

<style lang="sass">
@import "@/assets/css/_vars.sass"
.main__footer
    // position: fixed
    bottom: 8px
    left: 0px
    // margin-left: 248px
    display: flex
    align-items: center
    justify-content: space-between
    font-size: 12px
    font-weight: 500
    width: 100%
    height: 40px
    // padding: 0 16px
    background: white
    &_left
        display: flex
        align-items: center
        gap: 24px
        color: $mainFooterTextColor
        padding-top: 13px
        border-top: 1px solid $whiteSmoke
        width: 50%
        height: 100%
    &_right
        display: flex
        align-items: center
        gap: 24px
        padding-top: 13px
        border-top: 1px solid $whiteSmoke
        width: 50%
        justify-content: flex-end
        height: 100%
        // margin-right: 248px
    &_error
        display: flex
        align-items: center
        gap: 8px
        &-text
            text-decoration: underline
            color: $mainFooterErrorTextColor
            cursor: pointer
        &-count
            padding: 3px 8px
            color: $mainFooterErrorCountTextColor
            background: $mainFooterErrorCountBackgroundColor
            border-radius: 8px
            cursor: pointer
    &_text
        text-decoration: underline
        color: $linkBlue
        cursor: pointer
    &_count
        padding: 3px 8px
        color: $mainFooterErrorCountTextColor
        background: $moderateSlateBlue
        border-radius: 8px
        cursor: pointer
    &_fio
        color: $mainFooterFioTextColor
.print__dialog_body
    height: 100vh
.close__button_icon
    display: none
    pointer-events: none
.progress__bar_line
    height: 10px
.progress__bar_value
    background-color: #19B131
.icon__clear_button
    margin-right: 8px

</style>