import { api } from "@/api";

export default {
    state: {
        selectedAddress: null,
        isPatientAddressesLoading: false,
        patientAddress: null,
        patientAddresses: [],
        formCaseByIdTabAddressBtnStatus: false,
    },
    actions: {
        async addPatientAddress({ commit, dispatch }, data) {
            commit("updateIsPatientAddressesLoading", true);

            try {
                const token = await dispatch("getAccessToken");
                if (!token) {
                    commit("updateIsPatientAddressesLoading", false);
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    return;
                }
                const address = await api.postPatientAddress(data, token);
                commit("updatePatientAddress", address);
                commit("updatePatientAddresses", address);
                commit("updateIsPatientAddressesLoading", false);
            } catch (error) {
                commit("updateIsPatientAddressesLoading", false);
                console.error(error);
            }
        },
        async editLocalPatientAddress({ commit, dispatch }, [data, id]) {
            commit("updateIsPatientAddressesLoading", true);
            try {
                const token = await dispatch("getAccessToken");
                if (token) {
                    const address = await api.patchPatientAddress(
                        id,
                        data,
                        token
                    );
                    commit("updateIsPatientAddressesLoading", false);
                    commit("changeLocalPatientAddress", address);
                } else {
                    commit("updateIsPatientAddressesLoading", false);
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    return;
                }
            } catch (error) {
                commit("updateIsPatientAddressesLoading", false);
                console.log(error);
            }
        },
        async editPatientAddress(
            { commit, dispatch, rootGetters },
            [data, id]
        ) {
            commit("updateIsPatientAddressesLoading", true);
            try {
                const token = await dispatch("getAccessToken");
                if (token) {
                    await api.patchPatientAddress(id, data, token).then(() => {
                        dispatch(
                            "fetchSettingsReferencePatientById",
                            rootGetters.getSettingsReferencePatientById?.id
                        );
                    });
                    commit("updateIsPatientAddressesLoading", false);
                } else {
                    commit("updateIsPatientAddressesLoading", false);
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    return;
                }
            } catch (error) {
                commit("updateIsPatientAddressesLoading", false);
                console.log(error);
            }
        },
        async deletePatientAddress({ dispatch, commit }, id) {
            try {
                const token = await dispatch("getAccessToken");
                if (token) {
                    await api.deletePatientAddress(id, token);
                    commit("deleteAddressFormLocalPatient", id);
                } else {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    return;
                }
            } catch (error) {
                console.log(error);
            }
        },
        updateSelectedAddress({ commit }, data) {
            commit("changeSelectedAddress", data);
        },
        updateAddressActivity({ commit }, data) {
            commit("changeAddressActivity", data);
        },
        updateFormCaseByIdTabAddressBtnStatus({ commit }, status) {
            commit("changeFormCaseByIdTabAddressBtnStatus", status);
        },
        updatePatientAddressesBySearch({ commit }, [data, id]) {
            commit("changePatientAddressesBySearch", [data, id]);
        },
        clearPatientAddresses({ commit }) {
            commit("clearPatientAddresses");
        },
    },
    mutations: {
        updatePatientAddress(state, data) {
            state.patientAddress = data;
        },
        clearPatientAddresses(state) {
            state.patientAddresses = [];
        },
        changePatientAddressesBySearch(state, [data, id]) {
            console.log(id);
            if (id) {
                state.patientAddresses = data.map((item) => ({
                    ...item,
                    active: id === item.id ? true : false,
                }));
            } else {
                console.log(data);
                console.log(Array.isArray(data));
                if (Array.isArray(data)) {
                    state.patientAddresses = data;
                } else {
                    state.patientAddresses.push(data);
                }
            }
        },
        updatePatientAddresses(state, data) {
            state.patientAddresses.push({
                ...data,
                active: false,
            });
        },
        changeSelectedAddress(state, data) {
            state.selectedAddress = data;
        },
        updateIsPatientAddressesLoading(state, data) {
            state.isPatientAddressesLoading = data;
        },
        changeAddressActivity(state, data) {
            const selectedIndex = state.patientAddresses.findIndex(
                (item) => item.id === data.id
            );

            state.patientAddresses = state.patientAddresses.map(
                (item, index) => {
                    const uniqueId = `id-${Date.now().toString(
                        36
                    )}-${Math.random().toString(36).slice(2)}`;
                    if (index === selectedIndex) {
                        // Если выбранный элемент был неактивен, делаем его активным
                        return {
                            ...item,
                            uniqueId,
                            active: !item.active, // Переключаем состояние active для выбранного элемента
                        };
                    }
                    // Все остальные элементы должны быть неактивными
                    return {
                        ...item,
                        uniqueId,
                        active: false,
                    };
                }
            );
        },
        changeLocalPatientAddress(state, data) {
            const index = state.patientAddresses.findIndex(
                (item) => item.id === data.id
            );
            if (index !== -1) {
                state.patientAddresses.splice(index, 1, data);
            }
        },
        deleteAddressFormLocalPatient(state, id) {
            state.patientAddresses = state.patientAddresses.filter(
                (address) => address.id !== id
            );
        },
        changeFormCaseByIdTabAddressBtnStatus(state, status) {
            state.formCaseByIdTabAddressBtnStatus = status;
        },
    },
    getters: {
        getPatientAddress(state) {
            return state.patientAddress;
        },
        getPatientAddresses(state) {
            return state.patientAddresses;
        },
        getSelectedAddress(state) {
            return state.selectedAddress;
        },
        getIsPatientAddressesLoading(state) {
            return state.isPatientAddressesLoading;
        },
        getFormCaseByIdTabAddressBtnStatus(state) {
            return state.formCaseByIdTabAddressBtnStatus;
        },
    },
};
