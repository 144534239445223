import { createStore, createLogger } from "vuex";
// import { createStore } from "vuex";
// import createPersistedState from "vuex-persistedstate";
import auth from "@/store/modules/auth";
import preloaders from "@/store/modules/preloaders";
import permissions from "@/store/modules/permissions";
import cleanses from "@/store/modules/cleanses";
import inputs from "@/store/modules/inputs";
import adminInterfaceControlButtons from "@/store/modules/adminInterfaceControlButtons";
import saveButtonBlockFlags from "@/store/modules/saveButtonBlockFlags";
import modals from "@/store/modules/modals";
import forms from "@/store/modules/forms";
import lifetimeCaseRegisterNumber from "@/store/modules/lifetimeCaseRegisterNumber";
import lifetimeCases from "@/store/modules/lifetimeCases";
import lifetimeCasesModals from "@/store/modules/lifetimeCasesModals";
import lifetimeCasesForms from "@/store/modules/lifetimeCasesForms";
import lifetimeCasesPatients from "@/store/modules/lifetimeCasesPatients";
import lifetimeCasesMaterials from "@/store/modules/lifetimeCasesMaterials";
import lifetimeCasesMicroPreparationsPhotos from "@/store/modules/lifetimeCasesMicroPreparationsPhotos";
import lifetimeCasesBiopsyMaterials from "@/store/modules/lifetimeCasesBiopsyMaterials";
import lifetimeCasesDiseases from "@/store/modules/lifetimeCasesDiseases";
import lifetimeCasesMacroDescription from "@/store/modules/lifetimeCasesMacroDescription";
import lifetimeCasesMacroDescriptionPhotos from "@/store/modules/lifetimeCasesMacroDescriptionPhotos";
import lifetimeCasesMacroDescriptionObject from "@/store/modules/lifetimeCasesMacroDescriptionObject";
import lifetimeCasesMicroscopy from "@/store/modules/lifetimeCasesMicroscopy";
import lifetimeCasesGenerals from "@/store/modules/lifetimeCasesGenerals";
import lifetimeCasesPrint from "@/store/modules/lifetimeCasesPrint";
import lifetimeCasesReception from "@/store/modules/lifetimeCasesReception";
import lifetimeCasesRegistration from "@/store/modules/lifetimeCasesRegistration";
import lifetimeCasesReceptionDefect from "@/store/modules/lifetimeCasesReceptionDefect";
import lifetimeCaseStatuses from "@/store/modules/lifetimeCaseStatuses";
import lifetimeArchive from "@/store/modules/lifetimeArchive";
import settingsReferenceStaff from "@/store/modules/settingsReferenceStaff";
import settingsReferenceOrganizations from "@/store/modules/settingsReferenceOrganizations";
import settingsReferenceDepartments from "@/store/modules/settingsReferenceDepartments";
import settingsReferenceLocalizations from "@/store/modules/settingsReferenceLocalizations";
import settingsReferenceMaterial from "@/store/modules/settingsReferenceMaterial";
import settingsReferenceBiopsyType from "@/store/modules/settingsReferenceBiopsyType";
import settingsReferenceGender from "@/store/modules/settingsReferenceGender";
import settingsReferenceDefectTypeMaterial from "@/store/modules/settingsReferenceDefectTypeMaterial";
import settingsReferenceDefectTypeCase from "@/store/modules/settingsReferenceDefectTypeCase";
import organization from "@/store/modules/organization";
import mortemCases from "@/store/modules/mortemCases";
import mortemCasesModals from "@/store/modules/mortemCasesModals";
import profile from "@/store/modules/profile";
import toastsMessage from "@/store/modules/toastsMessage";
import samplePreparations from "@/store/modules/samplePreparations";
import settingsReferenceForms from "@/store/modules/settingsReferenceForms";
import settingsReferencePositions from "@/store/modules/settingsReferencePositions";
import settingsReferenceSamplePreparationDefects from "@/store/modules/settingsReferenceSamplePreparationDefects";
import lifetimeCaseInformation from "@/store/modules/lifetimeCaseInformation";
import settingsReferenceMedicalServices from "@/store/modules/settingsReferenceMedicalServices";
import samplePreparationDefects from "@/store/modules/samplePreparationDefects";
import settingsReferenceEquipment from "@/store/modules/settingsReferenceEquipment";
import samplePreparationStages from "@/store/modules/samplePreparationStages";
import settingsReferenceWiringProgram from "@/store/modules/settingsReferenceWiringProgram";
import settingsReferenceDecaltinationAgent from "@/store/modules/settingsReferenceDecaltinationAgent";
import settingsRegistrationNumbers from "@/store/modules/settingsRegistrationNumbers";
import settingsProductionCalendar from "@/store/modules/settingsProductionCalendar";
import mortemGeneral from "@/store/modules/mortemGeneral";
import mortemOrganization from "@/store/modules/mortemOrganization";
import mortemPersonInfo from "@/store/modules/mortemPersonInfo";
import mortemComment from "@/store/modules/mortemComment";
import mortemPerinatalPersonInfo from "@/store/modules/mortemPerinatalPersonInfo";
import mortemCaseById from "@/store/modules/mortemCaseById";
import mortemRegistrationInfo from "@/store/modules/mortemRegistrationInfo";
import mortemDiaseaseInfo from "@/store/modules/mortemDiaseaseInfo";
import mortemAutopsyBodyExamination from "@/store/modules/mortemAutopsyBodyExamination";
import mortemAutopsyAbdomen from "@/store/modules/mortemAutopsyAbdomen";
import mortemAutopsyThoracicCavity from "@/store/modules/mortemAutopsyThoracicCavity";
import mortemAutopsySkullCavity from "@/store/modules/mortemAutopsySkullCavity";
import mortemAutopsyCirculatoryOrgans from "@/store/modules/mortemAutopsyCirculatoryOrgans";
import mortemAutopsyRespiratorySystem from "@/store/modules/mortemAutopsyRespiratorySystem";
import mortemAutopsyDigestiveOrgans from "@/store/modules/mortemAutopsyDigestiveOrgans";
import mortemAutopsyGenitourinarySystem from "@/store/modules/mortemAutopsyGenitourinarySystem";
import mortemAutopsyBloodformingOrgans from "@/store/modules/mortemAutopsyBloodformingOrgans";
import mortemAutopsyEndocrineGlands from "@/store/modules/mortemAutopsyEndocrineGlands";
import mortemAutopsyMusculoskeletalSystem from "@/store/modules/mortemAutopsyMusculoskeletalSystem";
import mortemAutopsyPlacenta from "@/store/modules/mortemAutopsyPlacenta";
import mortemAutopsyMembranes from "@/store/modules/mortemAutopsyMembranes";
import mortemAutopsyAdditionalResearch from "@/store/modules/mortemAutopsyAdditionalResearch";
import mortemAutopsyComment from "@/store/modules/mortemAutopsyComment";
import mortemAutosyInformation from "@/store/modules/mortemAutosyInformation";
import mortemAutopsyMaterials from "@/store/modules/mortemAutopsyMaterials";
import mortemPregnancyAndChildbirth from "@/store/modules/mortemPregnancyAndChildbirth";
import dictiorariesNsi from "@/store/modules/dictiorariesNsi";
import settingsEmployees from "@/store/modules/settingsEmployees";
import settingsPermissions from "@/store/modules/settingsPermissions";
import settingsReferenceEmployeePositions from "@/store/modules/settingsReferenceEmployeePositions";
import settingsReferenceUsers from "@/store/modules/settingsReferenceUsers";
import referenceDefectCategories from "@/store/modules/referenceDefectCategories";
import referenceResponsibleStaffMembers from "@/store/modules/referenceResponsibleStaffMembers";
import referenceOrganizations from "@/store/modules/referenceOrganizations";
import referenceOrganizationDepartments from "@/store/modules/referenceOrganizationDepartments";
import referenceOrganizationDepartmentDoctors from "@/store/modules/referenceOrganizationDepartmentDoctors";
import referenceLocalizations from "@/store/modules/referenceLocalizations";
import referenceBiopsyForms from "@/store/modules/referenceBiopsyForms";
import referenceBiopsyPositions from "@/store/modules/referenceBiopsyPositions";
import referencePreviousOrganizations from "@/store/modules/referencePreviousOrganizations";
import settingsReferenceColoringMethods from "@/store/modules/settingsReferenceColoringMethods";
import settingsReferenceColorings from "@/store/modules/settingsReferenceColorings";
import integrationSemd from "@/store/modules/integrationSemd";
import semdIntegrationTable from "@/store/modules/semdIntegrationTable";
import metrics from "@/store/modules/metrics";
import integrationMis from "@/store/modules/integrationMis";
import settingsReferencePatients from "@/store/modules/settingsReferencePatients";
import patientIdentityDocuments from "@/store/modules/patientIdentityDocuments";
import patientInsuranceDocuments from "@/store/modules/patientInsuranceDocuments";
import patientAddresses from "@/store/modules/patientAddresses";
import patientLegalGuardian from "@/store/modules/patientLegalGuardian";

const debug = process.env.NODE_ENV !== "production";

// const morfisPersistedState = createPersistedState({
//     storage: window.localStorage,
//     paths: [
//         "menu",
//         "lifetimeCaseRegisterNumber.lifetimeRegisterNumberData",
//         "lifetimeCasesModals",
//         "lifetimeCases.lifetimeCaseId",
//         "lifetimeCasesMaterials.lifetimeMaterialAddBtn",
//         "lifetimeCasesMaterials.lifetimeBiopsySamples",
//         "lifetimeCasesDiseases.lifetimeBiopsyStudiesAddBtn",
//         "lifetimeCasesDiseases.lifetimePreviousBiopsyStudies",
//         "organization.organizationInformationData",
//         "lifetimeCasesMacroDescription.lifetimeMacroDescriptionBtn",
//         "lifetimeCasesMacroDescription.lifetimeMacroDescriptionTableData",
//         "lifetimeCasesMacroDescriptionObject",
//         "mortemCasesModals",
//         "mortemCases.mortemCaseId",
//         "profile",
//     ],
// });

export default createStore({
    modules: {
        auth,
        cleanses,
        inputs,
        preloaders,
        permissions,
        adminInterfaceControlButtons,
        saveButtonBlockFlags,
        modals,
        forms,
        lifetimeCaseRegisterNumber,
        lifetimeCases,
        lifetimeCasesModals,
        lifetimeCasesForms,
        lifetimeCasesPatients,
        lifetimeCasesMaterials,
        lifetimeCasesMicroPreparationsPhotos,
        lifetimeCasesMicroscopy,
        lifetimeCasesBiopsyMaterials,
        lifetimeCasesDiseases,
        lifetimeCasesGenerals,
        lifetimeCasesPrint,
        lifetimeCasesReception,
        lifetimeCasesReceptionDefect,
        lifetimeCaseStatuses,
        lifetimeCasesMacroDescription,
        lifetimeCasesMacroDescriptionPhotos,
        lifetimeCasesMacroDescriptionObject,
        lifetimeArchive,
        organization,
        mortemCases,
        mortemCasesModals,
        profile,
        settingsReferenceStaff,
        settingsReferenceOrganizations,
        settingsReferenceDepartments,
        settingsReferenceLocalizations,
        settingsReferenceMaterial,
        settingsReferenceBiopsyType,
        settingsReferenceGender,
        settingsReferenceDefectTypeMaterial,
        settingsReferenceDefectTypeCase,
        toastsMessage,
        samplePreparations,
        lifetimeCasesRegistration,
        settingsReferencePositions,
        settingsReferenceForms,
        settingsReferenceSamplePreparationDefects,
        lifetimeCaseInformation,
        settingsReferenceMedicalServices,
        samplePreparationDefects,
        settingsReferenceEquipment,
        samplePreparationStages,
        settingsReferenceWiringProgram,
        settingsReferenceDecaltinationAgent,
        settingsRegistrationNumbers,
        settingsProductionCalendar,
        mortemGeneral,
        mortemOrganization,
        mortemPersonInfo,
        mortemComment,
        mortemPerinatalPersonInfo,
        mortemCaseById,
        mortemRegistrationInfo,
        mortemDiaseaseInfo,
        mortemAutopsyBodyExamination,
        mortemAutopsyAbdomen,
        mortemAutopsyThoracicCavity,
        mortemAutopsySkullCavity,
        mortemAutopsyCirculatoryOrgans,
        mortemAutopsyRespiratorySystem,
        mortemAutopsyDigestiveOrgans,
        mortemAutopsyGenitourinarySystem,
        mortemAutopsyBloodformingOrgans,
        mortemAutopsyEndocrineGlands,
        mortemAutopsyMusculoskeletalSystem,
        mortemAutopsyPlacenta,
        mortemAutopsyMembranes,
        mortemAutopsyAdditionalResearch,
        mortemAutopsyComment,
        mortemAutosyInformation,
        mortemAutopsyMaterials,
        mortemPregnancyAndChildbirth,
        dictiorariesNsi,
        settingsEmployees,
        settingsPermissions,
        settingsReferenceEmployeePositions,
        settingsReferenceUsers,
        referenceDefectCategories,
        referenceResponsibleStaffMembers,
        referenceOrganizations,
        referenceOrganizationDepartments,
        referenceOrganizationDepartmentDoctors,
        referenceLocalizations,
        referenceBiopsyForms,
        referenceBiopsyPositions,
        referencePreviousOrganizations,
        settingsReferenceColoringMethods,
        settingsReferenceColorings,
        integrationSemd,
        semdIntegrationTable,
        metrics,
        integrationMis,
        settingsReferencePatients,
        patientIdentityDocuments,
        patientInsuranceDocuments,
        patientAddresses,
        patientLegalGuardian
    },
    strict: debug,
    plugins: debug ? [createLogger()] : []
    // plugins: debug && [createLogger()]
});
