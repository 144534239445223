<template>
    <component :is="layout">
        <Toast position="top-center">
            <template v-slot:icon>
                <i
                    v-if="severity === 'success'"
                    class="toast__icon"
                    v-html="getIconTemplate('icon-success')"
                ></i>
                <i
                    v-if="severity === 'info'"
                    class="toast__icon"
                    v-html="getIconTemplate('icon-info')"
                ></i>
                <i
                    v-if="severity === 'warn'"
                    class="toast__icon"
                    v-html="getIconTemplate('icon-exclamation')"
                ></i>
                <i
                    v-if="severity === 'error'"
                    class="toast__icon"
                    v-html="getIconTemplate('icon-error')"
                ></i>
            </template>
        </Toast>
        <router-view />
    </component>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { icons } from "@/mixins";

export default {
    name: "App",
    mixins: [icons],
    data() {
        return {
            severity: null,
        };
    },
    computed: {
        ...mapGetters(["getInterfaceMenuShowBtn", "getToastMessage"]),
        layout() {
            return this.$route?.meta?.layout || "page-layout";
        },
    },
    watch: {
        getToastMessage(newVal) {
            if (newVal) {
                this.showToast(newVal);
            }
        },
    },
    methods: {
        ...mapActions(["updateMenuShowBtnStatus"]),
        showToast(response) {
            switch (response.code) {
                case 200:
                    this.severity = "success";
                    this.$toast.add({
                        severity: "success",
                        summary: "Успех",
                        detail:
                            response.message ||
                            "Всё отлично! Запрос прошёл успешно.",
                        life: 3000,
                    });
                    break;
                case 400:
                    this.severity = "error";
                    this.$toast.add({
                        severity: "error",
                        summary: "Ошибка",
                        detail:
                            response.message ||
                            "Ошибка! Что-то пошло не так, проверьте свой запрос.",
                        life: 3000,
                    });
                    break;
                case 401:
                    this.severity = "warn";
                    this.$toast.add({
                        severity: "warn",
                        summary: "Предупреждение",
                        detail:
                            response.message ||
                            "Доступ запрещен! Пожалуйста, войдите в свой аккаунт или зарегистрируйтесь.",
                        life: 3000,
                    });
                    break;
                case 404:
                    this.severity = "warn";
                    this.$toast.add({
                        severity: "warn",
                        summary: "Предупреждение",
                        detail:
                            response.message ||
                            "Упс, не найдено! Запрашиваемая информация не обнаружена на сервере.",
                        life: 3000,
                    });
                    break;
                case 500:
                    this.severity = "error";
                    this.$toast.add({
                        severity: "error",
                        summary: "Ошибка",
                        detail:
                            response.message ||
                            "Внутренняя ошибка сервера! Мы уже работаем над этим, попробуйте еще раз позже.",
                        life: 3000,
                    });
                    break;
                default:
                    this.severity = "error";
                    this.$toast.add({
                        severity: "error",
                        summary: "Ошибка",
                        detail:
                            response.message ||
                            "Что-то пошло не так. Непредвиденный код ответа: " +
                                response.code,
                        life: 3000,
                    });
                    break;
            }
        },
    },
    // eslint-disable-next-line
    mounted() {
        if (window.innerWidth <= 992) {
            if (this.getInterfaceMenuShowBtn) {
                return;
            }
            this.updateMenuShowBtnStatus();
        }

        window.addEventListener("resize", () => {
            if (window.innerWidth > 992) {
                if (!this.getInterfaceMenuShowBtn) {
                    return;
                }
                this.updateMenuShowBtnStatus();
            } else {
                if (this.getInterfaceMenuShowBtn) {
                    return;
                }
                this.updateMenuShowBtnStatus();
            }
        });
    },
};
</script>

<style lang="sass">
@import "@/assets/css/_vars.sass"
@import url('https://fonts.googleapis.com/css2?family=Roboto+Flex:opsz,wght@8..144,300;8..144,400;8..144,500;8..144,600;8..144,700;8..144,800&display=swap')
//110 Buttons
//213 Containers
//418 CaseById___MortemCaseById
//615 MODAL WINDOW
*
    margin: 0
    padding: 0
    box-sizing: border-box
    font-family: "Roboto Flex", sans-serif
    letter-spacing: 0

body
    background: $bodyBackground
    color: $adminMainTextContentColor
    // overflow: hidden

html,
body
    width: 100%
    height: 100%
    scrollbar-color: rgba(0, 0, 0, .15)
    scrollbar-width: thin
    scrollbar-height: thin
button,
select,
input::placeholder,
input[type="date"]
    font-family: "Roboto Flex", sans-serif
    letter-spacing: 0

::-webkit-scrollbar
   width: 8px
   height: 8px

::-webkit-scrollbar-track
   background: white

::-webkit-scrollbar-thumb
   background-color: #E8E7EA
   border-radius: 4px
//    border: 0px solid rgba(0, 0, 0, .15)
//    background-image: linear-gradient(180deg,rgba(0, 0, 0, .15) 0%,rgba(255, 255, 255, .7) 90%)
input[type="date"]::-webkit-calendar-picker-indicator
    background-image: url(./assets/img/icon-calendar.svg)
    cursor: pointer
a
    text-decoration: none
    cursor: pointer

li
    list-style: none
    list-style-type: none
    cursor: pointer
.table__journal_cell--content[disabled],
.upload__image_macroscopy[disabled],
.dropdown__wrapper[disabled],
.cassette__textarea_form[disabled],
.table__cell_permission[disabled],
.upload__image_microscopy[disabled],
.permission__wrapper[disabled],
.dialog__body[disabled],
.table__cell[disabled],
.table__cell_checkbox[disabled]
    pointer-events: none
button,
input,
select,
textarea
    overflow: visible
    outline: none
    border-style: none
    font-family: "Roboto Flex", sans-serif
    letter-spacing: 0
    font-size: 14px
    font-weight: 500
    line-height: 20px
.field__label
    display: flex
    color: $darkPurple
    font-size: 12px
    font-style: normal
    font-weight: 500
    line-height: 10px
    margin-bottom: 8px !important
.input__field_required
    color: $violetPink
    font-size: 12px
    font-style: normal
    font-weight: 700
    margin-left: 2px
.dropdown__field_arrow
    position: absolute
    pointer-events: none
    & svg
        width: 16px
        height: 16px
        & path
            fill: $inputReferenceArrowIconColor !important
            transition: all 0.2s ease-in-out 0s
        & path:hover
            fill: $inputReferenceArrowIconHoverColor !important
.dropdown__field_input
    position: relative
    padding-right: 34px !important

.dropdown__field_arrow[disabled=true] svg path
    fill: $inputReferenceArrowIconDisabledColor !important
button
    cursor: pointer

.page
    display: flex
    flex-direction: column
    height: 100vh

.top
    flex-grow: 1
    display: flex
    align-items: center
    justify-content: center
.relative
    position: relative

//PRIMEVUE Tooltip
.p-tooltip .p-tooltip-arrow
    display: none
.p-tooltip .p-tooltip-text
    width: fit-content
    bottom: 0px
    background-color: $surfacePrimary
    color: black
    font-size: 12px
    padding: 10px
    border-radius: 4px
    border: 1px solid $tableJournalRowBorderColor

// Buttons
.influence__box
    display: flex
    align-items: center
    flex-wrap: wrap
    gap: 12px

.influence__box-filter
    display: flex
    width: 100%
    height: 100%
    align-items: center
    flex-direction: column
    justify-content: space-between
.influence__box-left
    display: flex
    flex-direction: column
    gap: 16px
    margin-bottom: 16px
.influence
    display: flex
    align-items: center
    justify-content: space-between
    // margin: 15px 0
.filter__block
    display: flex
    flex-direction: column
    gap: 12px
    &-title
        font-size: 14px
        font-weight: 500
        line-height: 20px
        color: $textPrimaryBlack
        padding: 12px 0px
        border-bottom: 1px solid $borderMediumGray
        margin-bottom: 8px
.filter__button_block
    display: flex
    flex-direction: column
    gap: 16px
    width: 100%
.button__background_style--grey,
.button__background_style--purple,
.button__background_style--violet,
.button__background_style--orange,
.button__background_style--crimson,
.button__background_style--disable,
.button__border_style--purple,
.button__border_style--crimson,
.button__border_style--carmine,
.button__border_style--orange,
.button__not_style--carmine,
.button__not_style--purple
    display: flex
    justify-content: center
    align-items: center
    border-radius: 4px
    transition: all 0.3s ease-in-out 0s
    letter-spacing: 0.05em
    cursor: pointer
    font-weight: 500
    padding: 9px 16px
    height: 38px
    font-size: 14px
.button__background_style--grey
    color: $buttonBackgroundStyleGreyTextColor
    background-color: $buttonBackgroundStyleGreyBackgroundColor
    &:hover
        color: $buttonBackgroundStyleGreyTextHoverColor
        background-color: $buttonBackgroundStyleGreyBackgroundHoverColor
.button__background_style--purple
    color: $buttonBackgroundStylePurpleTextColor
    background-color: $buttonBackgroundStylePurpleBackgroundColor
    &:hover
        color: $buttonBackgroundStylePurpleTextHoverColor
        background-color: $buttonBackgroundStylePurpleBackgroundHoverColor
    &:active
        background: $buttonBackgroundStylePurpleActiveColor
        outline: 1px solid white
        outline-offset: -2px
        border-radius: 6px
.button__background_style--violet
    color: $buttonBackgroundStyleVioletTextColor
    background-color: $buttonBackgroundStyleVioletBackgroundColor
    &:hover
        color: $buttonBackgroundStyleVioletTextHoverColor
        background-color: $buttonBackgroundStyleVioletBackgroundHoverColor
    &:active
        background: $buttonBackgroundStyleVioletActiveColor
        outline: 1px solid white
        outline-offset: -2px
        border-radius: 6px
.button__background_style--violet,
.button__background_style--purple
    &:disabled
        color: $buttonDisabledTextColor
        background-color: $buttonDisabledBackgroundColor
.button__background_style--orange
    color: $buttonBackgroundStyleOrangeTextColor
    background-color: $buttonBackgroundStyleOrangeBackgroundColor
    min-width: 48px
    &:hover
        color: $buttonBackgroundStyleOrangeTextHoverColor
        background-color: $buttonBackgroundStyleOrangeBackgroundHoverColor
.button__background_style--crimson
    color: $buttonBackgroundStyleCrimsonTextColor
    background-color: $buttonBackgroundStyleCrimsonBackgroundColor
    &:hover
        color: $buttonBackgroundStyleCrimsonTextHoverColor
        background-color: $buttonBackgroundStyleCrimsonBackgroundHoverColor
.button__background_style--disable
    color: $buttonBackgroundStyleDisableTextColor
    background-color: $buttonBackgroundStyleDisableBackgroundColor
    pointer-events: none
.button__border_style--purple
    background-color: transparent
    color: $buttonBorderStylePurpleTextColor
    border: 1px solid $buttonBorderStylePurpleBorderColor
    padding: 9px 16px
    &:hover
        color: $buttonBorderStylePurpleTextHoverColor
        border: 1px solid $buttonBorderStylePurpleBorderHoverColor
.button__border_style--crimson
    color: $buttonBorderStyleCrimsonTextColor
    background-color: transparent
    border: 1px solid $buttonBorderStyleCrimsonBorderColor
    padding: 9px 14px
    &:hover
        color: $buttonBorderStyleCrimsonTextHoverColor
        background-color: $buttonBorderStyleCrimsonBackgroundHoverColor
.button__border_style--carmine
    color: $buttonBorderStyleCarmineTextColor
    background-color: transparent
    border: 1px solid $buttonBorderStyleCarmineBorderColor
    padding: 9px 14px
    &:hover
        color: $buttonBorderStyleCarmineTextHoverColor
        border-color: $buttonBorderStyleCarmineBorderHoverColor
    &:disabled
        color: $buttonDisabledTextColor
        border-color: $buttonDisabledBackgroundColor
.button__border_style--orange
    color: $buttonBorderStyleOrangeTextColor
    background-color: transparent
    border: 1px solid $buttonBorderStyleOrangeBorderColor
    padding: 9px 16px
    min-width: 48px
    margin-left: 10px
    &:hover
        color: $buttonBorderStyleOrangeTextHoverColor
        border: 1px solid $buttonBorderStyleOrangeBorderHoverColor
.button__not_style--carmine
    background-color: transparent
    padding: 9px 16px
    color: $buttonNotStyleCarmineTextColor
    &:hover
        background-color: $buttonNotStyleCarmineBackgroundHoverColor
    &:disabled
        color: $buttonDisabledTextColor
        background-color: transparent
.button__not_style--purple
    background-color: transparent
    padding: 9px 16px
    color: $buttonNotStylePurpleTextColor
    &:hover
        color: $buttonNotStylePurpleTextHoverColor
        background-color: $buttonNotStylePurpleBackgroundHoverColor
.button__add_position--bottom
    position: absolute
    bottom: 40px
.edit
    margin-bottom: 10px
.additional__form
    margin-left: 12px
.save__all
    width: 100%
    margin-top: 10px
    margin-bottom: 10px
.save__all_block
    color: $blockButtonBackgroundStylePurpleTextColor
    background-color: $blockButtonBackgroundStylePurpleBackgroundColor
.button__gray_border-none
    border: none
    border-radius: 4px
    font-size: 14px
    font-weight: 500
    line-height: 20px
    padding: 9px 16px
    height: 38px
    background: transparent
    color: $buttonGrayTextColor
    &:hover
        background: $buttonGrayHoverBackgroundColor
    &_active
        color: $buttonGrayActiveTextColor
        background-color: $buttonGrayActiveBackgroundColor
    &:disabled
        color: $buttonGrayDisabledTextColor
        & path
            fill: $buttonGrayDisabledTextColor
.button__gray_border
    border: 1px solid $buttonGrayTextColor
    border-radius: 4px
    font-size: 14px
    font-weight: 500
    line-height: 20px
    padding: 9px 16px
    max-height: 38px
    background: transparent
    color: $buttonGrayTextColor
    display: flex
    align-items: center
    &:hover
        border-color: $buttonGrayBorderHoverColor
        color: $buttonGrayBorderHoverColor
    &:hover path
        fill: $buttonGrayBorderHoverColor
    &:active
        border-color: $buttonGrayBorderActiveColor
    &:active path
        fill: $buttonGrayBorderActiveColor
.print__button
    width: 126px
    gap: 8px
    padding: 9px 16px !important
.clear__filters
    margin-right: 10px

.search
    display: flex
    align-items: center
    flex-direction: column
    #search
        display: flex
        align-items: center
    &__box
        display: flex
        align-items: center
        // flex-direction: column
        position: relative
        #search_input
            width: 248px
            color: $adminMainTextContentColor
            font-weight: 500
            font-size: 14px
            min-height: 38px
            line-height: 20px
            border: 1px solid $inputSearchBorderColor
            border-radius: 4px
            padding: 0 16px
            &:focus,
            &:focus > .fa-search:focus
                border: 1px solid $inputSearchBorderFocusColor
            &::placeholder
                color: $inputPlaceholderTextColor
    &__box input
        width: 248px
    .search
        transition: all 0.3s ease-in-out 0s
        border: 1px solid $inputSearchBorderColor
        background: transparent
        border: none
        position: absolute
        right: 16px
        top: calc( 50% - 8px )

//Reference search
.reference__search
    gap: 12px
.reference__header
    display: flex
    align-items: center
    margin: 8px 0px
    &--title
        margin-left: 16px
        font-size: 16px
        font-style: normal
        font-weight: 700
.reference__dropdown
    padding: 16px
    display: flex
    flex-direction: row
    align-items: center
    gap: 12px

// Containers
.flag,
.badge
    display: flex
    align-items: center
    justify-self: center
    padding: 5px 10px
    border-radius: 4px
    justify-content: center !important
    text-transform: uppercase
    letter-spacing: 0.05em
    font-size: 12px
    font-style: normal
    font-weight: 500
    background-color: $flagAndBadgeNormalBGColor
    color: $flagAndBadgeNormalTextColor
.badge
    width: 154px
    height: 26px
.badge__status
    height: 38px
.badge__status_row
    height: 26px
.badge__monochrome
    background-color: $badgeMonochromeBGColor
.badge__samplepreparation
    background-color: $mainFooterFioBackgroundColor
    color: $violetPink
.badge__defect
    background-color: $palePink
    color: $errorRed
.badge__registered
    background-color: $surfacePrimary
    color: $tableSortIconHoverColor
.badge__reception
    background-color: $lavender
    color: $moderateSlateBlue
.badge__archive
    background-color: $whiteSmoke
    color: $dustyLavender
.badge__wip
    background-color: $badgeWIPBGColor
    color: $badgeWIPTextColor
.badge__done
    background-color: $notificationSuccessPatientBackgroundColor
    color: $notificationPatientSuccessBorderColor
.badge__wiring
.badge__microtomy
.badge__coloring
.badge__filling
.badge__samplereception_cito
    background-color: $badgeColoringBGColor
    color: $violetPink
.badge__additional_fixation
.badge__decaltination
    background-color: $appFormActionConfirmationBackgroundColor
    color: $orange
.flag
    padding: 7px 15px
.lifecase__status
    display: flex
    align-items: center
    height: 38px
    padding: 8px 16px
    font-size: 12px
    font-style: normal
    font-weight: 400
    color: #EB4E44
    border-radius: 4px
    background: #FDEDEC
.lifecase__status_disabled
    background-color: #F4F3F4
    color: #A59EAB
.flag__issue
    background-color: $flagIssueBGColor
    color: $flagIssueTextColor
.flag__critical
    background-color: $palePink
    color: $errorRed
.flag__refusal_autopsy
    background-color: $flagRefusalBGColor
    color: $flagRefusalTextColor

.content__container
    display: flex
    flex: 0 0 100%
    max-width: 100%
    &_scroll
        width: 100%
    &_card
        // border: 1px solid $adminContentContainerBorderBottomColor
        // border-radius: 4px
        word-wrap: break-word
        background-color: $bodyBackground
        // padding: 10px
        flex: 1 1 auto
        // box-shadow: 4px 2px 5px $boxShadowC, 10px 10px 10px $boxShadowF, 0 0 0 $boxShadowC inset, 0 0 0 $boxShadowF inset
        // box-shadow: 5px 5px 7px $boxShadowC, 10px 10px 10px $boxShadowF, 0 0 0 $boxShadowC inset, 0 0 0 $boxShadowF inset
    &_card-cases-journal
        margin-bottom: 0
        margin-top: 5px
        height: calc(100vh - 103px)
        overflow: auto
    &_card-cases-journal-sort
        margin-bottom: 16px
    &_card-cases-journal-sort-active
        margin-bottom: 16px
        background: $gray
        padding: 8px
    .card__body
        position: relative
        // overflow-x: auto
        // overflow-y: auto
        overflow: hidden
        display: block
        width: 100%

.reference__form_container
    position: relative
    height: calc(100vh - 94px)

.content__tables
    display: table
    width: 100%
    border-collapse: separate
    border-spacing: 1px 0
    .table
        display: table-row-group
    .table__header
        background-color: $tableTitleBGColor
    .rows
        display: table-row
        border-radius: 4px
        cursor: pointer
        &:hover
            background-color: $tableTitleBGColor
    .table__row_content
        display: table-cell
        padding: 3px
        font-size: 14px
        border-top: 0.5px solid $tableBorderBottomColor
        span,
        a
            display: flex
            justify-content: flex-start
            align-items: center
            transition: all 0.3s ease-in-out 0s
            white-space: nowrap
            padding: 8px
        a
            color: $adminMainTextContentColor
        a:hover
            color: $adminMainTableTextContentHoverColor
        .title__last
            justify-content: flex-end
        .actions
            display: flex
            justify-content: flex-end
            align-items: center
        .action__btn
            display: flex
            justify-content: center
            align-items: center
            font-size: 12px
            padding: 10px
            border-radius: 4px
            color: $white
            transition: all 0.2s ease
            &:hover
                box-shadow: 0px 2px 5px $authorizationBtnBoxShadow
            span
                display: flex
                justify-content: center
                align-items: center
                margin-left: 5px
        .fa-edit
            background-color: $editBtnBGColor
            color: $editBtnTextColor
        .fa-edit > span
            color: $editBtnTextColor
        .fa-trash-alt
            display: none //кнопка удаления пока скрыта по ненадобности
            background-color: $deleteBtnBGColor
            color: $deleteBtnTextColor
        .fa-trash-alt > span
            color: $deleteBtnTextColor
    .title
        border-top: none
        font-weight: 600

/******* Кнопки на тасках в таблицах форм ******/

.todo__item:hover
    opacity: 0.7
.todo__block_btn
    position: absolute
    display: flex
    align-items: center
    top: 9px
    transform: translateX(-5%)
    transition: all 0.3s ease-in-out 0s
    right: 0px
.todo__action
    display: flex
    justify-content: space-around
    align-items: center
    height: 24px
    width: 24px
    border-radius: 4px
    opacity: 0
    cursor: pointer
    transform: translateX(-5%)
    transition: all 0.3s ease-in-out 0s
    color: $white
    svg
        margin-top: 4px
.todo__action::before
    position: absolute
    font-size: 15px
.todo__action_delete
    background-color: $todoActionDeleteBackgroundColor
    margin-left: 5px
.todo__action_description
    background-color: $todoActionDescriptionBackgroundColor
.todo__action_update
    background-color: $todoActionUpdateBackgroundColor
.todo__item:hover .todo__block_btn
    right: 0px
.todo__item:hover .todo__action
    opacity: 0.8

.todo__item:hover .todo__action:hover
    opacity: 1

/**** CaseById___MortemCaseById ****/

.box__icon_edit
    display: flex
    align-items: center
    justify-content: space-between
    .fa-edit
        cursor: pointer
        padding: 5px 0 5px 10px
        width: 1.7em
        height: 1.7em
.box__icon
    justify-content: flex-end
.refusal__autopsy
    background-color: $refusalAutopsyBlockBGColor
    padding: 15px
.case
    &__container_card_Id
        padding: 8px 0
    &__tabs
        display: flex
        &_tab
            position: relative
            display: flex
            align-items: center
            justify-content: center
            font-size: 14px
            text-align: center
            line-height: 50px
            min-width: 150px
            height: 45px
            margin: 0 15px 10px 0
            border-top: 2px solid $caseTabsBtnBorderColor
            border-left: 2px solid $caseTabsBtnBorderColor
            border-right: 2px solid $caseTabsBtnBorderColor
            cursor: pointer
            color: $caseTabsBtnBorderColor
            border-radius: 4px 4px 0 0
            transition: all .4s
            padding: 12px
            .case__tabs_active,
            &:hover
                border-top: 2px solid $caseTabsBtnActiveBorderColor
                border-left: 2px solid $caseTabsBtnActiveBorderColor
                border-right: 2px solid $caseTabsBtnActiveBorderColor
                color: $caseTabsBtnActiveBorderColor
            .case__tabs_active,
            &:hover::before
                content: ""
                position: absolute
                bottom: 0
                left: -2px
                margin-bottom: -10px
                height: 11px
                border-left: 2px solid $caseTabsBtnActiveBorderColor
            &:nth-child(6)
                margin-right: 0
        &_active
            border-top: 2px solid $caseTabsBtnActiveBorderColor
            border-left: 2px solid $caseTabsBtnActiveBorderColor
            border-right: 2px solid $caseTabsBtnActiveBorderColor
            color: $purple
            &::before
                content: ""
                position: absolute
                bottom: 0
                left: -2px
                margin-bottom: -10px
                height: 11px
                border-left: 2px solid $purple
        &_disable
            pointer-events: none
    &__content
        width: 100%
        background: $surfacePrimary
        padding: 24px 20px
        border-radius: 4px
        &-general
            padding: 16px 16px 32px 16px
            display: flex
            flex-direction: column
            gap: 16px
            border-bottom: 1px dashed $softGray
        &_items
            position: relative
            width: 100%
            .items__box
                overflow: hidden
                display: flex
                flex-direction: column
                gap: 32px
                .disease__diagnosis_header
                    margin-top: 30px
                    margin-bottom: 10px
                    font-weight: normal
                &-subtitle
                    font-weight: 500
                    font-size: 14px
                    line-height: 20px
                    color: $slateGray
            &--indention
                margin-top: 20px
        &_header
            display: flex
            align-items: center
            justify-content: space-between
            // height: 38px
            width: 100%
            h4
                font-size: 14px
                font-weight: 700
                line-height: 20px
                letter-spacing: -0.14px
                color: $deepPurple
            &--border
                padding-bottom: 16px
                border-bottom: 1px solid $borderMediumGray
                margin-bottom: 12px
        &_header--defect
            display: flex
            align-items: center
            justify-content: space-between
            height: 38px
            h4
                font-size: 14px
                font-weight: 700
                line-height: 20px
                letter-spacing: -0.14px
                color: $defectBlockHeaderTextColor
        .content__box
            display: grid
            word-wrap: break-word
            grid-template-columns: 1fr 1fr
            .row
                display: grid
                word-wrap: break-word
                grid-template-columns: 210px 1fr
                padding: 5px 0px
                &__title
                    color: $adminProfilePageAdditionalTextContentColor
                    font-size: 14px
                &__value
                    font-size: 15px
            .obj__row
                grid-template-columns: 210px 1fr
            .row__template_column--one
                grid-template-columns: 1fr
                .row__template_column--one-value
                    margin-top: 5px
            .conclusion_header
                margin-bottom: 10px
            .row__mortem_autopsy
                grid-template-columns: 500px 1fr
        .content__box_one-column
            grid-template-columns: 1fr
        .content__box_customerTab
            .row
                grid-template-columns: 210px 1fr
        .content__box_disease
            .row
                grid-template-columns: 200px 1fr
        .content__box_mortem-disease
            .row
                grid-template-columns: 265px 1fr
        .content__box_settings--tab
            .row
                grid-template-columns: 340px 1fr
    .perinatal
        color: $caseByIdTabHeaderPerinatalTextColor
        text-transform: none
    &__content_box--header
        display: flex
        align-items: center
        gap: 24px
        .row_content
            display: flex
            align-items: center
            gap: 12px
            &__title
                color: $adminProfilePageAdditionalTextContentColor
                font-size: 11px
                font-weight: bold
                letter-spacing: normal
                text-transform: uppercase
            &__value
                font-size: 12px
            &__value > span
                font-weight: bold
    &__content_box
        display: flex
        gap: 64px
        .colum_1
            .row
                grid-template-columns: 90px 1fr
        .colum_2
            .row
                grid-template-columns: 125px 1fr
        .row
            display: grid
            word-wrap: break-word
            grid-template-columns: 155px 1fr
            padding: 5px 0px
            &__title
                font-family: "Roboto Flex", sans-serif
                letter-spacing: 0
                font-size: 14px
                font-weight: 700
                line-height: 20px
                letter-spacing: -0.14px
            &__value
                font-size: 12px
    .content__conclusion
        margin-bottom: 10px
    &__active
        display: block

.header__title_lifecase--card
    font-family: "Roboto Flex", sans-serif
    letter-spacing: 0
    font-size: 14px !important
    font-weight: 500
    line-height: 24px
    color: $textPrimaryBlack
.header__title_lifecase
    font-family: "Roboto Flex", sans-serif
    letter-spacing: 0
    font-size: 16px !important
    font-weight: 500
    line-height: 24px
    color: $textPrimaryBlack
    &--bold
        font-weight: 700
        font-size: 16px !important
.row__table
    margin-top: 20px
.content__container_card--header
    margin-top: 8px
.case__card_content--header
    display: flex
    align-items: center
    justify-content: space-between
    .flag__container
        display: flex
        align-items: center
        gap: 12px
        width: 50%
.print__container
    display: none
.case__card_container--button
    display: flex
    justify-content: flex-end
    align-items: center
    gap: 12px
.case__card_container--button > button
    margin-right: 10px

/***** FORM WINDOW ******/

.form__row
    display: flex
    flex-wrap: wrap
    .content__border
        display: flex
        justify-content: space-between
        align-items: center
        width: 100%
        border-bottom: 1px solid $softGray
        margin: 16px 0
        padding-bottom: 14px
    .row_content
        flex: 0 0 100%
        max-width: 100%
        padding-right: 5px
        padding-left: 5px
    .datetime_local
        align-items: center
        display: flex
        justify-content: center
        height: 38px
        margin-top: 22px
        margin-right: 5px
    .row__small
        flex: 0 0 50%
        max-width: 50%
    .row__small_lg8
        flex: 0 0 80%
        max-width: 80%
    .row__small_lg75
        flex: 0 0 75%
        max-width: 75%
    .row__small_sm3
        flex: 0 0 33.33%
        max-width: 33.33%
    .row__small_sm6
        flex: 0 0 60%
        max-width: 60%
    .row__small_sm66
        flex: 0 0 66.66%
        max-width: 66.66%
    .row__small_sm4
        flex: 0 0 40%
        max-width: 40%
    .row__small_sm48
        flex: 0 0 48%
        max-width: 48%
    .row__small_lt
        flex: 0 0 25%
        max-width: 25%
    .row__small_lt35
        flex: 0 0 35%
        max-width: 35%
    .row__small_lt3
        flex: 0 0 30%
        max-width: 30%
    .row__small_lt2
        flex: 0 0 20%
        max-width: 20%
    .row__small_lt15
        flex: 0 0 15%
        max-width: 15%
    .row__small_lt1
        flex: 0 0 10%
        max-width: 10%
    .row__small_lt11
        flex: 0 0 11.1%
        max-width: 11.1%
    .row__perinatal_mother-info
        margin-bottom: 15px
        margin-top: 15px
    .row__group
        display: flex
    .form__group_checkbox
        display: flex
        align-items: center
        justify-content: flex-end
    .form__group_leftcheckbox
        justify-content: start
    .form__group
        margin-bottom: 12px
        position: relative
        .form__control
            display: flex
            width: 100%
            height: 38px
            padding: 12px 16px 12px 16px
            font-size: 13px
            font-weight: 400
            line-height: 1.5
            background-color: $formInputBackgroundColor
            background-clip: padding-box
            border: 1px solid $formInputBorderColor
            border-radius: 4px
            &:focus
                border: 1px solid $formInputFocusBorderColor
            &:not(:placeholder-shown):invalid
                border: 1px solid $flagCriticalBGColor
            &:disabled
                background-color: $inputDisabledBackgroundColor
        .field__invalid,
        .field__invalid:focus
            border-color: $errorInputBorderColor
        .field__hint,
        .field__hint:focus
            border-color: $notificationHintBorderColor
    label
        margin-bottom: 3px
        transition: all 0.2s ease
        font-size: 12px
        display: flex
        align-items: center
        letter-spacing: 0.01em
    .input__group_date-time
        display: flex
    .form__control_date
        padding: 5px 12px
        height: 34px
    .form__control_time
        margin-left: 5px
    .form__control_checkbox
        width: auto
        margin-right: 5px
    .label__checkbox
        font-size: 12px
    .form__control_select
        height: 34px
    .form__control_textarea
        display: flex
        width: 100%
        padding: 12px 16px 12px 16px
        font-size: 13px
        font-weight: 400
        line-height: 1.5
        background-color: $formInputBackgroundColor
        background-clip: padding-box
        border: 1px solid $formInputBorderColor
        border-radius: 4px
        min-width: 100%
        max-width: 100%
        &::placeholder
            font-family: "Roboto Flex", sans-serif
            letter-spacing: 0
            font-size: 14px
            font-style: normal
            font-weight: 400
            color: $placeholderColor
    .form__control_textarea--row
        padding-left: 0 !important
        padding-right: 0 !important
        &-disease
            padding-left: 0 !important
            padding-right: 0 !important
            margin-top: 10px
.additional__content_form-pink
    border-bottom: 1px solid $violetPink
.additional__content_form-blue
    border-bottom: 1px solid $moderateSlateBlue
.additional__content_form-pink, .additional__content_form-blue
    padding-bottom: 20px
.additional__content_header--pink
    background-color: $pinkLavender
.additional__content_header--blue
    background-color: $lavender
.additional__content_header--pink, .additional__content_header--blue
    height: 52px
    padding: 0 16px
    display: flex
    align-items: center

.additional__content_title--pink
    color: $violetPink
.additional__content_title--blue
    color: $moderateSlateBlue
.additional__content_title--pink, .additional__content_title--blue
    font-size: 14px
    font-style: normal
    font-weight: 700
// .table__journal_header--white
//     background-color: white !important
.content__border_header
    font-size: 14px
    font-style: normal
    font-weight: 700
    color: $textPrimaryBlack
.content__border_header--purple
    color: $deepPurple
    font-size: 14px
    font-style: normal
    font-weight: 700
.notes__text
    color: $notesTextColor
    font-size: 12px
    &_form-data
        margin-bottom: 15px

.mark__checkbox, .colorful__checkbox, brand__checkbox
    appearance: none
.mark__checkbox:checked,
.mark__checkbox:not(:checked),
.colorful__checkbox:checked,
.colorful__checkbox:not(:checked),
.brand__checkbox:checked,
.brand__checkbox:not(:checked)
    position: absolute
    left: -9999px
.mark__checkbox:checked + label,
.mark__checkbox:not(:checked) + label,
.colorful__checkbox:checked + label,
.colorful__checkbox:not(:checked) + label
    display: flex
.mark__checkbox:checked + label,
.mark__checkbox:not(:checked) + label,
.colorful__checkbox:checked + label,
.colorful__checkbox:not(:checked) + label,
.brand__checkbox:checked + label,
.brand__checkbox:not(:checked) + label
    position: relative
    padding-left: 20px
    cursor: pointer
.mark__checkbox:checked + .mortem__general_checkbox,
.mark__checkbox:not(:checked) + .mortem__general_checkbox,
.colorful__checkbox:checked + .mortem__general_checkbox,
.colorful__checkbox:not(:checked) + .mortem__general_checkbox,
.brand__checkbox:checked + .mortem__general_checkbox,
.brand__checkbox:not(:checked) + .mortem__general_checkbox
    margin-top: 43px
.mark__checkbox:checked + label:before,
.mark__checkbox:not(:checked) + label:before,
.colorful__checkbox:checked + label:before,
.colorful__checkbox:not(:checked) + label:before,
.brand__checkbox:checked + label:before,
.brand__checkbox:not(:checked) + label:before
    content: ""
    position: absolute
    left: 0
    top: 0
    transition: all 0.2s ease-in-out 0s
.mark__checkbox:checked + label:before,
.mark__checkbox:not(:checked) + label:before
    width: 13px
    height: 13px
    border: 1px solid $formInputBorderColor
    background-color: $white
.colorful__checkbox:checked + label:before,
.colorful__checkbox:not(:checked) + label:before
    width: 15px
    height: 15px
    background-color: $formCheckboxColorfulBackgroundColor
.brand__checkbox:checked + label:before
    background-color: $brandCheckboxBackgroundCheckedColor
.brand__checkbox:not(:checked) + label:before
    background-color: $brandCheckboxBackgroundColor
.brand__checkbox:checked + label:before,
.brand__checkbox:not(:checked) + label:before
    width: 16px
    height: 16px
#release:checked + label:before,
#release:not(:checked) + label:before
    top: 3px
.mark__checkbox:checked + label:hover:before,
.mark__checkbox:not(:checked) + label:hover:before
    border: 1px solid $formInputBorderHoverColor
    color: $formInputHoverColor
.brand__checkbox:checked + label:hover:before,
.brand__checkbox:not(:checked) + label:hover:before
    background-color: $brandCheckboxBackgroundHoverColor
.label__checkbox:hover
    color: $formInputHoverColor
.mark__checkbox:checked + label:before,
.mark__checkbox:not(:checked) + label:before,
.colorful__checkbox:checked + label:before,
.colorful__checkbox:not(:checked) + label:before,
.brand__checkbox:checked + label:before,
.brand__checkbox:not(:checked) + label:before
    border-radius: 3px
.mark__checkbox:checked + label:after,
.mark__checkbox:not(:checked) + label:after,
.colorful__checkbox:checked + label:after,
.colorful__checkbox:not(:checked) + label:after,
.brand__checkbox:checked + label:after,
.brand__checkbox:not(:checked) + label:after
    content: ""
    position: absolute
    transition: all 0.2s ease
.mark__checkbox:checked + label:after,
.mark__checkbox:not(:checked) + label:after
    left: 1px
    top: 2px
    width: 10px
    height: 5px
    border-radius: 1px
    border-left: 3px solid $formInputCheckboxFlagBorderColor
    border-bottom: 3px solid $formInputCheckboxFlagBorderColor
    transform: rotate(-45deg)
.colorful__checkbox:checked + label:after,
.colorful__checkbox:not(:checked) + label:after
    left: 2px
    top: 2px
    width: 11px
    height: 11px
    border-radius: 2px
    background-color: $formCheckboxColorfulFlagBackgroundColor
.brand__checkbox:checked + label:after,
.brand__checkbox:not(:checked) + label:after
    left: 3px
    top: 3px
    width: 8px
    height: 5px
    border-radius: 2px
    border-left: 2px solid white
    border-bottom: 2px solid white
    transform: rotate(-45deg)
#release:checked + label:after,
#release:not(:checked) + label:after
    top: 5px
.mark__checkbox:checked + label:hover:after,
.mark__checkbox:not(:checked) + label:hover:after
    border-left: 3px solid $formInputCheckboxFlagBorderHoverColor
    border-bottom: 3px solid $formInputCheckboxFlagBorderHoverColor
.mark__checkbox:not(:checked) + label:after,
.colorful__checkbox:not(:checked) + label:after,
.brand__checkbox:not(:checked) + label:after
    opacity: 0
.mark__checkbox:checked + label:after,
.colorful__checkbox:checked + label:after,
.brand__checkbox:checked + label:after
    opacity: 1

#add_table_inputs
    background-color: $additionalFormBackgroundColor
    border-radius: 4px
    transition: all 0.3s ease-in-out 0s
    padding: 20px 16px !important
    margin-bottom: 20px

/**** WidgetSortDateFromTo ****/

.sort__form
    display: flex
    flex-direction: column
    gap: 12px
.sort__date_input
    width: 128px
    max-height: 38px
    color: $widgetSortDateFromToInputTextColor
    font-size: 14px
    font-weight: 500
    padding: 12px 12px 12px 16px
    border: 1px solid $widgetSortDateFromToInputBorderColor
    border-radius: 4px

/* field (input) notification */
.field__invalid,
.field__invalid:focus
    border-color: $errorInputBorderColor
.field__hint,
.field__hint:focus
    border-color: $notificationHintBorderColor
.field__invalid_message
    padding: 0 5px
    background-color: $notificationTextBackgroundColor
    color: $notificationErrorTextColor
    position: absolute
    top: 85%
    left: 7px
    pointer-events: none
    font-size: 12px
.field__invalid_message--without-label
    top: 80%
.field__message--notification
    color: $notificationTextColor
.field__message--hint
    color: $notificationHintTextColor
.field__message_range--second
    left: 365px

.position--relative
    position: relative

.display__none
    display: none

// height for page content containers

.height__card_case
    // height: calc(100vh - 194px)
.height__card_case--sort
    // overflow: auto
    // height: calc(100vh - 179px)
.height__card_case--grid
    overflow: auto
    height: calc(100vh - 124px)
.height__card_case--grid-sort
    height: calc(100vh - 185px)
.height__card_case--id
    overflow: auto
    height: calc(100vh - 316px)
.height__card_reference,
.height__card_settings
    overflow: auto
    height: calc(100vh - 132px)

@media only screen and (max-width: 768px)
    .case
        .case__tabs_tab
            margin: 0 5px 10px 0
            font-size: 14px
        .content__box
            grid-template-columns: 1fr
            .row
                grid-template-columns: 1fr 1fr
@media print
    @page
        margin: 0
        padding-left: 3cm
        padding-right: 1cm
        padding-bottom: 2cm
        padding-top: 2cm
    .content__container_card
        display: none
    .case__tabs_tab
        display: none
    .case__container_card
        display: none
    .print
        border: none
        color: black
        background: none
        &:hover, &:hover::before
            border: none
        &::before
            border: none
    .print__container
        display: block
    .case__card_container--button
        display: none

/* for view LifetimeCases.vue */
.dropdown__print_container
    position: relative

.margin__bottom
    margin-bottom: 12px
.general__edit-block
    display: flex
    justify-content: space-between
    align-items: center
    padding-top: 8px
    padding-bottom: 14px
    margin-bottom: 20px
    border-bottom: 1px solid $borderMediumGray
    width: 100%
.general__container
    margin-top: 16px
    width: 100%
    display: flex
    flex-direction: column
    &_top
        display: flex
        padding-top: 4px
        align-items: center
.upload__button
    width: 96px
    height: 96px
    justify-content: center
    align-items: center
    border-radius: 4px
    background: $deepPurple
    &_icon svg g path
        fill: white
.accordion__tab
    background-color: transparent
    color: $textPrimaryBlack
.accordion__tab_header
    border: 1px solid $borderMediumGray
    padding: 8px 8px 8px 16px
    border-radius: 8px
.close__image-button
    position: absolute
    right: -7px
    top: -7px
    display: flex
    width: 24px
    height: 24px
    flex-direction: column
    justify-content: center
    align-items: center
    border-radius: 8px
    background: $errorRed
    cursor: pointer
// .content__border
//     display: flex
//     justify-content: space-between
//     align-items: center
//     width: 100%
//     border-bottom: 1px solid $borderMediumGray
//     margin: 20px 0
//     padding-bottom: 14px
//     &_header
//         font-size: 14px
//         font-style: normal
//         font-weight: 600
//     &_header--purple
//         color: $deepPurple !important
//         font-size: 14px !important
.p-toast
    top: 32px !important
.p-toast .p-toast-message .p-toast-message-content
    padding: 16px 24px !important
    align-items: center !important
    gap: 12px
.p-toast .p-toast-message .p-toast-message-content .p-toast-summary
    font-family: "Roboto Flex", sans-serif
    letter-spacing: 0
    font-size: 14px
    line-height: 20px
    letter-spacing: -0.14px
    color: $textPrimaryBlack
    font-weight: 700
.p-toast .p-toast-message .p-toast-message-content .p-toast-detail
    font-family: "Roboto Flex", sans-serif
    letter-spacing: 0
    font-size: 14px
    font-weight: 500
    line-height: 20px
    color: $textPrimaryBlack
    letter-spacing: -0.14px
.p-toast-icon-close
    display: none
.p-toast .p-toast-message.p-toast-message-success
    background: $notificationSuccessPatientBackgroundColor
    border-radius: 2px
    border: none
    border-bottom: 3px solid $notificationPatientSuccessBorderColor
.p-toast .p-toast-message.p-toast-message-error
    background: $notificationErrorPatientBackgroundColor
    border-radius: 2px
    border: none
    border-bottom: 3px solid $errorRed
.p-toast .p-toast-message.p-toast-message-warn
    background: $appFormActionConfirmationBackgroundColor
    border-radius: 2px
    border: none
    border-bottom: 3px solid $orange
.p-toast .p-toast-message.p-toast-message-info
    background: $lavender
    border-radius: 2px
    border: none
    border-bottom: 3px solid $moderateSlateBlue
.toast__icon svg
    width: 24px !important
    height: 24px !important
//CONFIRM POPUP
.confirmation__popup_container
    position: relative
.confirmation__popup_message
    display: flex
    flex-direction: column
    justify-content: center
    &--row
        text-align: center
//NEW BUTTONS!!!!!!!!!!!!
.p-button-label
    padding: 0 8px !important
    font-weight: 400
    max-width: max-content
.p-button:focus
    box-shadow: none !important
.button__icon
    width: 16px
    height: 16px
    svg
        width: 16px
        height: 16px
    &_notext
        padding-left: 8px
    &_blue
        path
            fill: $moderateSlateBlue
    &_white
        path
            fill: $white
    &_purple
        path
            fill: $deepPurple
    &_reverse
        flex-direction: row-reverse
    &_small
        margin-bottom: 2px
        svg
            width: 12px !important
            height: 12px !important
.button__focus-no
    &:focus
        background: transparent !important
.button__transparent-blue
    display: flex
    height: 38px
    min-width: 48px
    padding: 12px 16px
    justify-content: center
    align-items: center
    border-radius: 4px
    background: transparent
    color: $moderateSlateBlue
    &:hover
        background: $lavender
    &:active
        background: $lavender
    &--active
        display: flex
        height: 38px
        min-width: 48px
        padding: 12px 16px
        justify-content: center
        align-items: center
        border-radius: 4px
        color: $moderateSlateBlue
        background: $lavender
.button__blue
    display: flex
    height: 38px
    min-width: 48px
    padding: 12px 16px
    justify-content: center
    align-items: center
    border-radius: 4px
    background: $moderateSlateBlue
    color: $white
    &:hover
        background: $buttonBlueHoverColor
    &:active
        background: $buttonBlueActiveColor
    &:focus
        background: $buttonBlueActiveColor
    &:disabled
        color: $dustyLavender
        background: $gray
        & path
            fill: $dustyLavender
    &_border
        display: flex
        height: 38px
        min-width: 48px
        padding: 12px 16px
        justify-content: center
        align-items: center
        border-radius: 4px
        background: transparent
        border: 1px solid $moderateSlateBlue
        color: $moderateSlateBlue
        &:hover
            color: $buttonBlueHoverColor
            border: 1px solid $buttonBlueHoverColor
        &:active
            color: $buttonBlueActiveColor
            border: 1px solid $buttonBlueActiveColor
        &:focus
            color: $buttonBlueActiveColor
            border: 1px solid $buttonBlueActiveColor
        &:disabled
            color: $dustyLavender
            border: none
    &_border-none
        border: none
        display: flex
        height: 38px
        min-width: 48px
        padding: 12px 16px
        justify-content: center
        align-items: center
        border-radius: 4px
        background: transparent
        color: $moderateSlateBlue
        &:hover
            background: $lavender
        &:active
            background: $whiteSmoke
        &:focus
            background: $lavender
        &:disabled
            color: $dustyLavender
        &-active
            height: 38px
            min-width: 48px
            padding: 12px 16px
            color: $moderateSlateBlue
            background: $lavender
.button__violet
    display: flex
    height: 38px
    min-width: 48px
    padding: 12px 16px
    justify-content: center
    align-items: center
    border-radius: 4px
    background: $violetPink
    color: $white
    & path
        fill: $white
    &:hover
        background: $orchidPurple
    &:active
        background: $mediumOrchid
    &:focus
        background: $mediumOrchid
    &:disabled
        color: $dustyLavender
        background: $surfacePrimary
        & path
            fill: $dustyLavender
    &_border
        display: flex
        height: 38px
        min-width: 48px
        padding: 12px 16px
        justify-content: center
        align-items: center
        border-radius: 4px
        background: transparent
        border: 1px solid $violetPink
        color: $violetPink
        & path
            fill: $violetPink
        &:hover
            color: $orchidPurple
            border: 1px solid $orchidPurple
            & path
                fill: $orchidPurple
        &:active
            color: $mediumOrchid
            border: 1px solid $mediumOrchid
            & path
                fill: $mediumOrchid
        &:focus
            color: $mediumOrchid
            border: 1px solid $mediumOrchid
            & path
                fill: $mediumOrchid
        &:disabled
            color: $dustyLavender
            border: none
            & path
                fill: $dustyLavender
    &_border-none
        border: none
        display: flex
        height: 38px
        min-width: 48px
        padding: 12px 16px
        justify-content: center
        align-items: center
        border-radius: 4px
        background: transparent
        color: $violetPink
        & path
            fill: $violetPink
        &:hover
            background: $surfacePrimary
        &:active
            background: $whiteSmoke
        &:focus
            background: $whiteSmoke
        &:disabled
            color: $dustyLavender
            & path
                fill: $dustyLavender
.button__purple
    display: flex
    height: 38px
    min-width: 48px
    padding: 12px 16px
    justify-content: center
    align-items: center
    border-radius: 4px
    background: $deepPurple
    color: $white
    font-weight: 400
    &:hover
        background: $mediumPurple
    &:active
        background: $royalPurple
    &:focus
        background: $royalPurple
    &:disabled
        color: $dustyLavender
        background: $surfacePrimary
        & path
            fill: $dustyLavender
    &_border
        display: flex
        height: 38px
        min-width: 48px
        padding: 12px 16px
        justify-content: center
        align-items: center
        border-radius: 4px
        background: transparent
        border: 1px solid $deepPurple
        color: $deepPurple
        & path
            fill: $deepPurple
        &:hover
            color: $mediumPurple
            border: 1px solid $mediumPurple
            & path
                fill: $mediumPurple
        &:active
            color: $royalPurple
            border: 1px solid $royalPurple
            & path
                fill: $royalPurple
        &:focus
            color: $royalPurple
            border: 1px solid $royalPurple
            & path
                fill: $royalPurple
        &:disabled
            color: $dustyLavender
            border: none
            & path
                fill: $dustyLavender
    &_border-none
        border: none
        display: flex
        height: 38px
        min-width: 48px
        padding: 12px 16px
        justify-content: center
        align-items: center
        border-radius: 4px
        background: transparent
        color: $deepPurple
        font-weight: 400
        &_active
            color: $royalPurple
            background: $baseBrandAccentLow
        & path
            fill: $deepPurple
        &:hover
            background: $baseBrandAccentLow
        &:active
            color: $royalPurple
            background: $baseBrandAccentLow
        &:focus
            color: $royalPurple
            background: $baseBrandAccentLow
        &:disabled
            color: $dustyLavender
            & path
                fill: $dustyLavender
.button__orange
    display: flex
    height: 38px
    min-width: 48px
    padding: 12px 16px
    justify-content: center
    align-items: center
    border-radius: 4px
    background: $orange
    color: $white
    &:hover
        background: $softOrange
    &:active
        background: $activeOrange
    &:focus
        background: $activeOrange
    &:disabled
        color: $dustyLavender
        background: $surfacePrimary
        & path
            fill: $dustyLavender
    &_border
        display: flex
        height: 38px
        min-width: 48px
        padding: 12px 16px
        justify-content: center
        align-items: center
        border-radius: 4px
        background: transparent
        border: 1px solid $orange
        color: $orange
        & path
            fill: $orange
        &:hover
            color: $softOrange
            border: 1px solid $softOrange
            & path
                fill: $softOrange
        &:active
            color: $activeOrange
            border: 1px solid $activeOrange
            & path
                fill: $activeOrange
        &:focus
            color: $activeOrange
            border: 1px solid $activeOrange
            & path
                fill: $activeOrange
        &:disabled
            color: $dustyLavender
            border: none
            & path
                fill: $dustyLavender
    &_border-none
        border: none
        display: flex
        height: 38px
        min-width: 48px
        padding: 12px 16px
        justify-content: center
        align-items: center
        border-radius: 4px
        background: transparent
        color: $orange
        & path
            fill: $orange
        &:hover
            background: $gray
        &:active
            color: $activeOrange
            background: $whiteSmoke
        &:focus
            color: $activeOrange
            background: $whiteSmoke
        &:disabled
            color: $dustyLavender
            & path
                fill: $dustyLavender
.button__red
    display: flex
    height: 38px
    min-width: 48px
    padding: 12px 16px
    justify-content: center
    align-items: center
    border-radius: 4px
    background: $errorRed
    color: $white
    &:hover
        background: $lightCarminePink
    &:active
        background: $activeRed
    &:focus
        background: $activeRed
    &:disabled
        color: $dustyLavender
        background: $surfacePrimary
        & path
            fill: $dustyLavender
    &_border
        display: flex
        height: 38px
        min-width: 48px
        padding: 12px 16px
        justify-content: center
        align-items: center
        border-radius: 4px
        background: transparent
        border: 1px solid $errorRed
        color: $errorRed
        & path
            fill: $errorRed
        &:hover
            color: $lightCarminePink
            border: 1px solid $lightCarminePink
            & path
                fill: $lightCarminePink
        &:active
            color: $activeRed
            border: 1px solid $activeRed
            & path
                fill: $activeRed
        &:focus
            color: $activeRed
            border: 1px solid $activeRed
            & path
                fill: $activeRed
        &:disabled
            color: $dustyLavender
            border: none
            & path
                fill: $dustyLavender
    &_border-none
        border: none
        display: flex
        height: 38px
        min-width: 48px
        padding: 12px 16px
        justify-content: center
        align-items: center
        border-radius: 4px
        background: transparent
        color: $errorRed
        & path
            fill: $errorRed
        &:hover
            background: $notificationErrorPatientBackgroundColor
        &:active
            color: $activeRed
            background: $notificationErrorPatientBackgroundColor
        &:focus
            color: $activeRed
            background: $notificationErrorPatientBackgroundColor
        &:disabled
            color: $dustyLavender
            & path
                fill: $dustyLavender
.button__green
    display: flex
    height: 38px
    min-width: 48px
    padding: 12px 16px
    justify-content: center
    align-items: center
    border-radius: 4px
    background: $green
    color: $white
    &:hover
        background: $hoverGreen
    &:active
        background: $activeGreen
    &:focus
        background: $activeGreen
    &:disabled
        color: $dustyLavender
        background: $surfacePrimary
        & path
            fill: $dustyLavender
    &_border
        display: flex
        height: 38px
        min-width: 48px
        padding: 12px 16px
        justify-content: center
        align-items: center
        border-radius: 4px
        background: transparent
        border: 1px solid $green
        color: $green
        & path
            fill: $green
        &:hover
            color: $hoverGreen
            border: 1px solid $hoverGreen
            & path
                fill: $hoverGreen
        &:active
            color: $activeGreen
            border: 1px solid $activeGreen
            & path
                fill: $activeGreen
        &:focus
            color: $activeGreen
            border: 1px solid $activeGreen
            & path
                fill: $activeGreen
        &:disabled
            color: $dustyLavender
            border: none
            & path
                fill: $dustyLavender
    &_border-none
        border: none
        display: flex
        height: 38px
        min-width: 48px
        padding: 12px 16px
        justify-content: center
        align-items: center
        border-radius: 4px
        background: transparent
        color: $green
        & path
            fill: $green
        &:hover
            background: $gray
        &:active
            color: $activeGreen
            background: $whiteSmoke
        &:focus
            color: $activeGreen
            background: $whiteSmoke
        &:disabled
            color: $dustyLavender
            & path
                fill: $dustyLavender
.filter__button_width
    width: 96px
.dialog
    width: 920px
    padding: 24px
    background: $white
    border-radius: 12px
.dialog__body_subtitle
    color: $slateGray
    font-size: 12px
    font-style: normal
    font-weight: 700
    line-height: 18px
.p-dialog-content
    display: flex
    flex-direction: column
    gap: 12px
    padding-bottom: 16px
    border: none
    font-size: 14px
    font-weight: 500
    line-height: 20px
    letter-spacing: -0.14px
.p-dialog .p-dialog-header
    font-size: 16px
    font-weight: 700
    line-height: 24px
    letter-spacing: -0.16px
    padding-bottom: 16px
    margin-bottom: 16px
    border-bottom: 1px solid $softGray
.p-dialog .p-dialog-footer
    display: flex
    align-items: center
    justify-content: flex-end
    gap: 12px
    border: none
    margin-top: 16px

.p-inputswitch
    width: 44px
    height: 24px
    &.p-focus .p-inputswitch-slider
        box-shadow: none
    & .p-inputswitch-slider
        border-radius: 12px
        background-color: $baseBrandAccentLow
    & .p-inputswitch-slider::before
        border-radius: 12px
        width: 16px
        height: 16px
        left: 4px
        margin-top: -8px
    & .p-component .p-focus
        box-shadow: none
    &.p-inputswitch-checked .p-inputswitch-slider
        background-color: $deepPurple
    &.p-inputswitch-checked .p-inputswitch-slider:before
        transform: translateX(19px)
    &.p-component.p-inputswitch-checked.additional__header > span
        background-color: $mediumOrchid
    &.p-component.additional__header > span
        background-color: $mediumPinkLavender
.empty__space_add--button
    width: 96px
    height: 96px
.fileuploader__wrapper
    // margin-bottom: 20px
    position: relative
.upload__prewiew-empty
    width: 100%
    // height: 124px
    padding: 32px
    display: flex
    justify-content: center
    color: $darkPurple
    font-size: 12px
    font-weight: 500
    background-color: $baseLow
.image__upload
    display: flex
    align-items: center
    gap: 12px
    // padding-bottom: 20px
    // overflow-x: auto
    .image__upload-buttonbar
        position: absolute
        z-index: 1
        background: $white !important
        border: none !important
        gap: 0
        border-radius: 0
        padding-top: 8px
    .image__upload-content
        display: flex
        flex-wrap: wrap
        align-items: center
        gap: 12px
        border: none !important
        border-radius: 0
        padding-top: 8px
        width: 100%
        height: 100%
    .uploaded__image-wrapper
        position: relative
    .uploaded__image
        width: 96px
        height: 96px
        border-radius: 4px
.image__upload_position
    position: relative
    display: flex
    align-items: start
.icon-cross-small path
    fill: $white
//CHECKBOX
.checkbox__form_block
    display: flex
    align-items: center
.checkbox__form_block:hover .checkbox__label_form
    color: $formInputHoverColor
.checkbox__label_form
    font-size: 12px
    font-style: normal
    font-weight: 500
    line-height: 18px
    padding: 0 0 3px 4px
    transition: all 0.3s ease-in-out 0s
//TABLE
.table__row_toggler
    width: 16px
    height: 16px
    path
        fill: $deepPurple
        stroke-width: 10px !important
.cassette__button_row[aria-expanded=true]
    transform: rotate(0deg)
    transition: 200ms
.cassette__button_row[aria-expanded=false]
    transform: rotate(-90deg)
    transition: 200ms
.table__journal
    // display: table
    // border-collapse: collapse
    // min-width: 100%
    // width: 100%
    // text-align: left
    // font-size: 11px
    // hyphens: auto
    // word-wrap: break-word
    &_container
        position: relative
        height: calc(100vh - 186px)
        overflow: auto
        &--height
            height: calc(100vh - 188px)
    &_wrapper
        height: calc(100vh - 194px)
        &--height
            height: calc(100vh - 188px)
    &_header--cell
        cursor: pointer
        border-top: none
        border-bottom: 1px solid $tableJournalRowBorderColor
        background-color: $tableJournalHeaderBackgroundColor
        font-size: 12px
        font-style: normal
        font-weight: 700
        line-height: 18px
        height: 52px
        white-space: nowrap
        padding: 12px
    &_header--cell:first-child
        border-radius: 4px 0px 0px 0px
    &_header--cell:last-child
        border-radius: 0px 4px 0px 0px
    &_header--cell:hover .sort__default_ascending svg path,
    &_header--cell:hover .sort__default_descending svg path
        fill: $tableSortIconHoverColor
    &_header--cell-content
        display: flex
        align-items: center
        color: $textPrimaryBlack
    &_header--checkbox
        height: 16px
        width: 16px
        border: none
        background-color: $baseBrandAccentLow
        transition: all 0.3s ease-in-out 0s
        &--none
            display: none
    &_header--checkbox:hover
        background-color: $mediumPurple
    &_header--checkbox.p-highlight
        border: none
        background-color: $deepPurple
    &_header--checkbox.p-highlight:hover
        background-color: $mediumPurple
    &_header--checkbox.p-focus
        box-shadow: none
        border: none
    &_row
        cursor: pointer
        height: 40px
        font-size: 12px
        font-style: normal
        font-weight: 500
        line-height: 18px
        background-color: white
    &_row:hover,
    &_row.p-highlight:hover
        background-color: $tableJournalRowHoverBackgroundColor
    &_row.p-highlight
        // background-color: transparent
        color: black
    &_row:last-child > td
        border-bottom: 1px solid $tableJournalRowBorderColor
    &_cell
        border-top: none
        border-bottom: 1px solid $borderMediumGray
        color: $textPrimaryBlack
    &_cell--checkbox
        padding-left: 12px
        padding-right: 12px
        border-top: none
        border-bottom: 1px solid $borderMediumGray
    &_cell--checkbox div
        display: flex
        align-items: center
    &_cell--content
        display: flex
        align-items: center
        height: 40px
        padding-left: 12px
        padding-right: 12px
    &_cell--text
        text-overflow: ellipsis
        overflow: hidden
        white-space: nowrap
    &_semd
        &_signed
            background-color: $buttonBlueHoverColor
            width: 12px
            height: 12px
            border-radius: 50%
        &_validated
            background-color: $hoverGreen
            width: 12px
            height: 12px
            border-radius: 50%
        &_returned
            background-color: $lightCarminePink
            width: 12px
            height: 12px
            border-radius: 50%
    &_mis
        &_notsent
            background-color: $softOrange
            width: 12px
            height: 12px
            border-radius: 50%
        &_ready
            background-color: $buttonBlueHoverColor
            width: 12px
            height: 12px
            border-radius: 50%
        &_sent
            background-color: $hoverGreen
            width: 12px
            height: 12px
            border-radius: 50%
        &_error
            background-color: $lightCarminePink
            width: 12px
            height: 12px
            border-radius: 50%
.table__header--cell:hover .sort__default_ascending svg path,
.table__header--cell:hover .sort__default_descending svg path
    fill: $tableSortIconHoverColor
.sort__active_ascending path, .sort__active_descending path
    fill: $tableSortIconActiveColor
.header__cell_cito
    margin-top: 6px
.table__cito_icon
    display: flex
.header__cell_cito path
    fill: $tableJournalHeaderCitoIconColor
.table__sample_row--expanded
    display: none
.table__cell_disable--checkbox
    height: 16px
    width: 16px
    border: none
    transition: all 0.3s ease-in-out 0s
    background-color: $dustyLavender
.table__journal_cell--stage
    display: flex
    height: 26px !important
    justify-content: center
    align-items: center
    border-radius: 4px
    margin: 8px 12px
.table__journal_cell--width
    padding-right: 8px !important
    padding-left: 8px !important
.empty__data_block
    display: flex
    margin-top: 8px
    padding: 32px 0px
    margin-bottom: 10px
    flex-direction: column
    align-items: center
    width: 100%
    border-radius: 4px
    background-color: $baseLow
    font-size: 12px
    font-style: normal
    font-weight: 500
    color: $slateGray
.empty__data_block--journal
    position: fixed
    display: flex
    margin-top: 10px
    padding: 32px 0px
    margin-bottom: 10px
    flex-direction: column
    align-items: center
    border-radius: 4px
    background-color: $baseLow
    font-size: 12px
    font-style: normal
    font-weight: 500
    color: $slateGray
    width: calc(100% - 272px)
    &-collapsed-sidebar
        width: calc(100% - 119px)
.table__in_step--icon
    display: flex
    justify-content: center
.filter__sidebar
    width: 416px
    height: 100%
    padding: 24px
    border-top-left-radius: 12px
    border-bottom-left-radius: 12px
.filter__dropdown-wrapper
    width: 208px
.filter__date-wrapper
    display: flex
    align-items: center
    gap: 8px
.filter__date
    width: 128px
.status__align_center
    width: 100%
    justify-content: center
.sort__icon_container
    margin-left: 4px
    display: flex
    align-items: center
    justify-content: center
    flex-direction: column
.sort__default_up
    margin-top: -4px
.sort__default_down
    margin-top: -12px

.template__radio_button--block
    display: flex
    margin-top: 12px
    height: 16px
    align-items: center
.template__radio_button--text
    font-size: 12px
.p-radiobutton
    width: 16px
    height: 16px
.p-radiobutton-box
    width: 16px
    height: 16px
    background: #ced4da
    box-shadow: none !important
.p-radiobutton-box.p-highlight
    border-color: $deepPurple
    background: $deepPurple
.p-radiobutton-icon
    width: 8px
    height: 8px

//SAMPLE PREPARATION STYLES
.sample__dialog
    display: flex
    flex-direction: column
    gap: 16px
.sample__dialog_wrapper
    padding-bottom: 22px
    padding-top: 4px
    border-bottom: 1px dashed $softGray
.sample__dialog_dropdown--wrapper
    display: flex
    flex-direction: column
    gap: 12px
.sample__dialog_date--wrapper
    display: flex
    gap: 12px
    padding-top: 12px
.sample__dialog_date--block
    display: flex
    align-items: flex-end
    gap: 10px
.sample__dialog_date
    width: 175px
    display: flex
    flex-direction: column
    gap: 8px
.sample__dialog_date--label
    font-size: 12px
    font-weight: 500
    line-height: 18px
    color: $darkPurple
.settings__content_container
    padding: 16px 16px 32px 16px
.settings__content_container--top
    padding: 0 16px 32px 16px
.settings__registration
    &_content
        margin-top: 20px
        display: flex
        flex-direction: column
        gap: 16px
        padding-bottom: 32px
    &_border
        border-bottom: 1px dashed $softGray
    &_item
        display: flex
        align-items: flex-start
        font-size: 14px
        line-height: 20px
        gap: 24px
    &_item--none
        display: none
    &_item--title
        width: 400px
        font-weight: 700
    &_item--content
        font-weight: 500
        display: flex
        gap: 8px
        align-items: baseline
.information__link
    color: $linkBlue
    text-decoration-line: underline
    display: flex
    align-items: center
    gap: 3px
    i
        margin-top: 4px
//TABLE LOADER
.table__loader
    animation: rotate 2s linear infinite
    width: 50px
    height: 50px
    & path
        fill: $spinnerButtonBackgroundColor
        stroke-linecap: round
        animation: load 1.5s ease-in-out infinite
.table__overlay
    position: absolute
    top: 0
    left: 0
    z-index: 1040
    width: 100%
    height: 100%
    background-color: white
    transition: opacity .15s linear
    opacity: 0.5
    display: flex
    justify-content: center
    align-items: center
.user__icon
    svg
        width: 20px
        height: 20px
        path
            fill: #6a3f97
//NOTIFICATION BLOCK
.notification__block
    display: flex
    width: 100%
.field__notification_icon
    margin-right: 12px
    margin-top: 3px
.field__notification,
.field__info,
.field__success
    width: 100%
    display: flex
    height: 38px
    padding: 9px 20px 9px 17px
    align-items: center
    color: $notificationPatientTextColor
    font-size: 12px
    font-style: normal
    font-weight: 500
    border-radius: 4px
.field__notification
    margin: 0 12px 20px 0
.field__notification, .field__info
    border-left: 3px solid $notificationPatientBorderColor
    background-color: $notificationPatientBackgroundColor
.field__success
    margin: 0 12px 20px 0
    border-left: 3px solid $notificationPatientSuccessBorderColor
    background-color: $notificationSuccessPatientBackgroundColor
.field__error,
.field__not_found
    border-left: 3px solid $notificationPatientErrorBorderColor
    background-color: $notificationErrorPatientBackgroundColor
.field__position--flex-end
    display: flex
    justify-content: flex-end
    align-items: center
    margin-right: 0

.field__position--row
    display: flex
    align-items: center
    .form__group
        margin-bottom: 0
//TABS
.p-tabview .p-tabview-nav
    height: 54px
    border: none
    border-bottom: 2px solid $borderMediumGray
.p-tabview .p-tabview-nav .p-tabview-header
    display: flex
    justify-content: center
    width: 160px
.p-tabview .p-tabview-nav .p-tabview-header .p-tabview-nav-link
    border: none
    padding: 0px 16px
    color: $textPrimaryBlack
    font-family: "Roboto Flex", sans-serif
    letter-spacing: 0
    font-size: 14px
    font-style: normal
    font-weight: 700
    transition: box-shadow 0.15s
    width: 100%
    border-radius: 0px
    display: flex
    justify-content: center
.p-tabview .p-tabview-nav .p-tabview-header:hover
    border-bottom: 2px solid $slateGray
.p-tabview .p-tabview-nav .p-highlight
    border-bottom: 2px solid $violetPink
    &:hover
        border-bottom: 2px solid $violetPink
.p-tabview .p-tabview-nav .p-tabview-header:hover,
.p-tabview .p-tabview-nav .p-highlight:hover,
.p-tabview .p-tabview-nav .p-highlight
    top: 2px
    position: relative
    padding-bottom: 2px
.p-tabview .p-tabview-nav .p-highlight .p-tabview-nav-link
    color: $violetPink

.lifetimecase__general-list
    display: flex
    flex-direction: column
    gap: 16px
    width: 100%
.lifetimecase__general-item
    display: flex
    align-items: center
    gap: 20px
    font-size: 14px
    font-weight: 500
    line-height: 14px
    letter-spacing: -0.14px
    width: fit-content
.lifetimecase__general-item--title
    font-weight: 700
    width: 200px
    flex-shrink: 1
    color: $textPrimaryBlack
.lifetimecase__general-item--column
    display: flex
    flex-direction: column
    align-items: flex-start
    gap: 12px
    font-size: 14px
    font-weight: 500
    line-height: 14px
    letter-spacing: -0.14px
.lifetimecase__general-item--title-column
    width: 100% !important
.lifetimecase__general-item--content
    font-size: 14px
    font-weight: 500
    line-height: 14px
    color: $textPrimaryBlack
//MORTEM
.mortem
    &__container
        display: flex
        flex-direction: column
        gap: 12px
    &__items
        display: flex
        align-items: flex-end
        gap: 12px
    &__header
        padding: 12px 0px 16px 0px
        font-weight: 500px
        line-height: 20px
        font-size: 14px
        border-bottom: 1px solid $borderMediumGray
        color: $textPrimaryBlack
        margin-bottom: 8px
        &-purple
            font-size: 14px
            font-weight: 700
            line-height: 20px
            color: $deepPurple
            border-bottom: 1px solid $borderMediumGray
            margin-bottom: 8px
            padding: 12px 0px 16px 0px
            display: flex
            align-items: center
            justify-content: space-between
    &__subheader
        padding: 16px 0px
        font-size: 14px
        font-weight: 500
        line-height: 20px
        color: $slateGray
//WIZARD
.p-steps .p-steps-item:before
    content: none
.wizard
    &__wrapper
        width: 920px
        height: 100%
        display: flex
        flex-direction: column
        padding: 64px 0
        margin: 0 auto
    &__header
        color: $deepPurple
        font-size: 20px
        font-weight: 700
        line-height: 28px
        letter-spacing: -0.4px
        display: flex
        align-items: center
        gap: 16px
    &__header_icon
        display: flex
        align-items: center
        justify-content: center
        svg
            width: 32px
            height: 32px
            path
                fill: $deepPurple
    &__steps
        padding-top: 48px
        padding-bottom: 24px
        display: flex
        align-items: center
        flex-direction: row
    &__step
        display: flex
        align-items: center
        gap: 8px
    &__step:last-child
        .wizard__icon_right
            display: none
    &__label
        font-size: 18px
        font-style: normal
        font-weight: 500
        line-height: 26px
        letter-spacing: -0.27px
    &__icon_right
        display: flex
        align-items: center
        justify-content: center
        padding-right: 16px
        padding-left: 8px
        svg
            width: 24px
            height: 24px
            path
                fill: $moderateSlateBlue
    &__content
        display: flex
        flex-direction: column
        gap: 12px
        padding-top: 40px
        padding-bottom: 32px
    &__content_title
        font-size: 20px
        font-weight: 700
        line-height: 28px
        letter-spacing: -0.4px
        color: $textPrimaryBlack
    &__content_description
        font-size: 16px
        font-weight: 500
        line-height: 24px
        letter-spacing: -0.16px
        color: $textPrimaryBlack
        padding: 24px 0px
    &__content_organization
        display: flex
        flex-direction: column
        gap: 12px
        padding: 12px 0
    &__buttons
        display: flex
        align-items: center
        justify-content: flex-end
        padding-top: 16px
    &__buttons--right
        display: flex
        align-items: center
        gap: 12px
.additional__tab
    background: $gray
    padding: 20px 16px
    display: flex
    flex-direction: column
    gap: 16px
    &_title
        width: 100%
        font-size: 12px
        font-weight: 700
        line-height: 18px
        padding-bottom: 16px
        border-bottom: 1px solid $softGray
    &_items
        display: flex
        align-items: center
        gap: 12px
.additional__tab_items:last-child
    border-bottom: none
    padding-bottom: 0
    .slide-fade-enter-active
    transition: transform 0.3s ease-out

.slide-fade-leave-active
    transition: transform 0.8s cubic-bezier(1, 0.5, 0.8, 1)

.slide-fade-enter-from,
.slide-fade-leave-to
    transform: translateY(20px)

.btn__container
    flex: 0 0 100%
    max-width: 100%
    display: flex
    justify-content: flex-end
/***** MODAL WINDOW ******/

.modal
    position: fixed
    top: 0
    left: 0
    z-index: 1050
    width: 100%
    height: 100%
    overflow: hidden
    outline: 0
    &__open
        overflow-x: hidden
        overflow-y: auto
    &__dialog
        max-width: 960px
        margin: 30px auto
        min-height: calc(100% - 150px)
        display: flex
        align-items: center
    &__content
        display: flex
        flex-direction: column
        padding: 24px
        width: 100%
        min-height: calc(100% - 150px)
        pointer-events: auto
        background-color: $bodyBackground
        background-clip: padding-box
        border-radius: 12px
        outline: 0
.modal__content_header
    display: flex
    align-items: center
    justify-content: space-between
    border-bottom: 1px solid $modalContentHeaderBorderBottomColor
    &--height
        padding-bottom: 16px
.modal__title
    font-size: 16px
    font-style: normal
    font-weight: 700
    color: $textPrimaryBlack
.modal__content_body
    flex: 1 1 auto
    min-height: 100px
.confirmation__popup_position--exit-case
    top: 46px
    right: 0
.mortem__dialog
    width: 920px
    padding: 24px
    background: $white
    display: flex
    flex-direction: column
    gap: 16px
    border-radius: 12px
    &-header
        display: flex
        align-items: center
        justify-content: space-between
        padding-bottom: 24px
        border-bottom: 1px solid #D2CFD5
        margin-bottom: 0px !important
        width: 100%
    &-title
        font-weight: 700
        font-size: 16px
        line-height: 24px
    &-content
        display: flex
        flex-direction: column
        gap: 16px
        color: $textPrimaryBlack
.close__button_icon
    display: none
.confirmation__popup_position--save-case
    bottom: 46px
    left: 50%
    transform: translate(-50%, 0)
.integration__documents
    display: flex
    gap: 20px
    font-size: 14px
    line-height: 20px
    align-items: center
    padding: 6px 0px
    &-title
        font-weight: 700
.dropdown__print
    margin-top: 10px
    position: absolute
    background-color: $popupPrintSelectListBackgroundColor
    z-index: 5
    right: 0
    width: max-content
    overflow: hidden
    padding: 4px
    border-radius: 8px
    display: flex
    flex-direction: column
    gap: 4px
    box-shadow: 0px 0px 8px 0px rgba(210, 207, 213, 0.75)
    &_list
        &--item
            transition: all 0.3s ease-in-out 0s
            width: 100%
            border-radius: 4px
            display: flex
            align-content: center
            justify-content: flex-start
            font-size: 13px
            color: $popupPrintSelectListTextColor
            padding: 12px 16px
            min-width: 240px
            &:hover
                color: $popupPrintSelectListHoverTextColor
                background: $popupPrintSelectListHoverBackgroundColor
                border-radius: 4px
            &-disable
                pointer-events: none
                width: 100%
                border-radius: 4px
                display: flex
                align-content: center
                justify-content: flex-start
                font-size: 13px
                color: $placeholderColor
                padding: 12px 16px
                background: $popupPrintSelectListHoverBackgroundColor

.edit__container
    padding: 9px 0 20px
    border-bottom: 1px solid $deepPurple
    &_header, &_header--purple
        line-height: 20px
        font-size: 14px
        margin-bottom: 20px
        display: flex
        align-items: center
        justify-content: space-between
    &_header
        font-weight: 500
        color: $textPrimaryBlack
        border-bottom: 1px solid $borderMediumGray
        padding: 13px 0px 13px
    &_header--purple
        font-weight: 700
        color: $deepPurple
        border-bottom: 1px solid $deepPurple
        padding: 0 0px 9px
</style>
