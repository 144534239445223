export default {
    state: {
        formCaseByIdTabsDisableBtnStatus: false,
        formCaseByIdTabPatientBtnStatus: false,
        formCaseByIdTabGeneralBtnStatus: false,
        formCaseByIdTabMaterialBtnStatus: false,
        formCaseByIdTabCustomerBtnStatus: false,
        formCaseByIdTabDefectBtnStatus: false,
        formCaseByIdTabDiseaseBtnStatus: false,
        formCaseByIdTabCommentBtnStatus: false,
        formCaseByIdTabInformationBtnStatus: false,
        formCaseByIdTabReceptionCommentBtnStatus: false,
        formCaseByIdTabSampleCommentBtnStatus: false,
        formCaseByIdTabReceptionInformationBtnStatus: false,
        formCaseByIdTabMacroDescriptionBtnStatus: false,
        formCaseByIdTabMacroDescriptionPhotoBtnStatus: false,
        formCaseByIdTabMacroDescriptionObjectBtnStatus: false,
        formCaseByIdTabMacroDescriptionConclusionBtnStatus: false,
        formCaseByIdTabMacroDescriptionCommentBtnStatus: false,
        formCaseByIdTabMacroDescriptionInformationBtnStatus: false,
        formCaseByIdTabMicroDescriptionBtnStatus: false,
        formCaseByIdTabMicroDescriptionMicroPreparationsBtnStatus: false,
        formCaseByIdTabMicroDescriptionConclusionBtnStatus: false,
        formCaseByIdTabMicroDescriptionServicesBtnStatus: false,
        formCaseByIdTabMicroDescriptionCommentBtnStatus: false,
        formCaseByIdTabMicroDescriptionResearchResponsibleBtnStatus: false,
        formCaseByIdTabMicroDescriptionInfoBtnStatus: false,
        formCaseByIdTabInformationFileUploaderBtnStatus: false,
    },
    actions: {
        updateFormCaseByIdTabsDisableBtnStatus({ commit }, status) {
            commit("changeFormCaseByIdTabsDisableBtnStatus", status);
        },
        updateFormCaseByIdTabAllBtnStatus({ commit }, status) {
            commit("changeFormCaseByIdTabsDisableBtnStatus", status);
            commit("changeFormCaseByIdTabPatientBtnStatus", status);
            commit("changeFormCaseByIdTabAddressBtnStatus", status);
            commit("changeFormCaseByIdTabGeneralBtnStatus", status);
            commit("changeFormCaseByIdTabMaterialBtnStatus", status);
            commit("changeFormCaseByIdTabCustomerBtnStatus", status);
            commit("changeFormCaseByIdTabDiseaseBtnStatus", status);
            commit("changeFormCaseByIdTabCommentBtnStatus", status);
            commit("changeFormCaseByIdTabInformationBtnStatus", status);
            commit(
                "changeFormCaseByIdTabReceptionInformationBtnStatus",
                status
            );
            commit("changeFormCaseByIdTabMacroDescriptionBtnStatus", status);
            commit(
                "changeFormCaseByIdTabMacroDescriptionPhotoBtnStatus",
                status
            );
            commit(
                "changeFormCaseByIdTabMacroDescriptionObjectBtnStatus",
                status
            );
            commit(
                "changeFormCaseByIdTabMacroDescriptionConclusionBtnStatus",
                status
            );
            commit("changeFormCaseByIdTabMicroDescriptionBtnStatus", status);
            commit(
                "changeFormCaseByIdTabMicroDescriptionMicroPreparationsBtnStatus",
                status
            );
            commit(
                "changeFormCaseByIdTabMicroDescriptionConclusionBtnStatus",
                status
            );
            commit(
                "changeFormCaseByIdTabMicroDescriptionServicesBtnStatus",
                status
            );
            commit(
                "changeFormCaseByIdTabMicroDescriptionCommentBtnStatus",
                status
            );
            commit(
                "changeFormCaseByIdTabMicroDescriptionResearchResponsibleBtnStatus",
                status
            );
            commit(
                "changeFormCaseByIdTabMicroDescriptionInfoBtnStatus",
                status
            );
        },
        updateFormCaseByIdTabPatientBtnStatus({ commit }, status) {
            commit("changeFormCaseByIdTabPatientBtnStatus", status);
        },
        updateFormCaseByIdTabGeneralBtnStatus({ commit }, status) {
            commit("changeFormCaseByIdTabGeneralBtnStatus", status);
        },
        updateFormCaseByIdTabMaterialBtnStatus({ commit }, status) {
            commit("changeFormCaseByIdTabMaterialBtnStatus", status);
        },
        updateFormCaseByIdTabCustomerBtnStatus({ commit }, status) {
            commit("changeFormCaseByIdTabCustomerBtnStatus", status);
        },
        updateFormCaseByIdTabDefectBtnStatus({ commit }, status) {
            commit("changeFormCaseByIdTabDefectBtnStatus", status);
        },
        updateFormCaseByIdTabDiseaseBtnStatus({ commit }, status) {
            commit("changeFormCaseByIdTabDiseaseBtnStatus", status);
        },
        updateFormCaseByIdTabCommentBtnStatus({ commit }, status) {
            commit("changeFormCaseByIdTabCommentBtnStatus", status);
        },
        updateFormCaseByIdTabInformationBtnStatus({ commit }, status) {
            commit("changeFormCaseByIdTabInformationBtnStatus", status);
        },
        updateFormCaseByIdTabReceptionInformationBtnStatus({ commit }, status) {
            commit(
                "changeFormCaseByIdTabReceptionInformationBtnStatus",
                status
            );
        },
        updateFormCaseByIdTabReceptionCommentBtnStatus({ commit }, status) {
            commit("changeFormCaseByIdTabReceptionCommentBtnStatus", status);
        },
        updateFormCaseByIdTabSampleCommentBtnStatus({ commit }, status) {
            commit("changeFormCaseByIdTabSampleCommentBtnStatus", status);
        },
        updateFormCaseByIdTabMacroDescriptionAllBtnStatus({ commit }, status) {
            commit("changeFormCaseByIdTabMacroDescriptionBtnStatus", status);
            commit(
                "changeFormCaseByIdTabMacroDescriptionPhotoBtnStatus",
                status
            );
            commit(
                "changeFormCaseByIdTabMacroDescriptionObjectBtnStatus",
                status
            );
            commit(
                "changeFormCaseByIdTabMacroDescriptionConclusionBtnStatus",
                status
            );
        },
        updateFormCaseByIdTabMacroDescriptionBtnStatus({ commit }, status) {
            commit("changeFormCaseByIdTabMacroDescriptionBtnStatus", status);
        },
        updateFormCaseByIdTabMacroDescriptionPhotoBtnStatus(
            { commit },
            status
        ) {
            commit(
                "changeFormCaseByIdTabMacroDescriptionPhotoBtnStatus",
                status
            );
        },
        updateFormCaseByIdTabMacroDescriptionObjectBtnStatus(
            { commit },
            status
        ) {
            commit(
                "changeFormCaseByIdTabMacroDescriptionObjectBtnStatus",
                status
            );
        },
        updateFormCaseByIdTabMacroDescriptionConclusionBtnStatus(
            { commit },
            status
        ) {
            commit(
                "changeFormCaseByIdTabMacroDescriptionConclusionBtnStatus",
                status
            );
        },
        updateFormCaseByIdTabMacroDescriptionCommentBtnStatus(
            { commit },
            status
        ) {
            commit(
                "changeFormCaseByIdTabMacroDescriptionCommentBtnStatus",
                status
            );
        },
        updateFormCaseByIdTabMacroDescriptionInformationBtnStatus(
            { commit },
            status
        ) {
            commit(
                "changeFormCaseByIdTabMacroDescriptionInformationBtnStatus",
                status
            );
        },
        updateFormCaseByIdTabMicroDescriptionBtnStatus({ commit }, status) {
            commit("changeFormCaseByIdTabMicroDescriptionBtnStatus", status);
        },
        updateFormCaseByIdTabMicroDescriptionMicroPreparationsBtnStatus(
            { commit },
            status
        ) {
            commit(
                "changeFormCaseByIdTabMicroDescriptionMicroPreparationsBtnStatus",
                status
            );
        },
        updateFormCaseByIdTabMicroDescriptionConclusionBtnStatus(
            { commit },
            status
        ) {
            commit(
                "changeFormCaseByIdTabMicroDescriptionConclusionBtnStatus",
                status
            );
        },
        updateFormCaseByIdTabMicroDescriptionServicesBtnStatus(
            { commit },
            status
        ) {
            commit(
                "changeFormCaseByIdTabMicroDescriptionServicesBtnStatus",
                status
            );
        },
        updateFormCaseByIdTabMicroDescriptionCommentBtnStatus(
            { commit },
            status
        ) {
            commit(
                "changeFormCaseByIdTabMicroDescriptionCommentBtnStatus",
                status
            );
        },
        updateFormCaseByIdTabMicroDescriptionResearchResponsibleBtnStatus(
            { commit },
            status
        ) {
            commit(
                "changeFormCaseByIdTabMicroDescriptionResearchResponsibleBtnStatus",
                status
            );
        },
        updateFormCaseByIdTabMicroDescriptionInfoBtnStatus({ commit }, status) {
            commit(
                "changeFormCaseByIdTabMicroDescriptionInfoBtnStatus",
                status
            );
        },
        updateFormCaseByIdTabInformationFileUploaderBtnStatus(
            { commit },
            status
        ) {
            commit(
                "changeFormCaseByIdTabInformationFileUploaderBtnStatus",
                status
            );
        },
    },
    mutations: {
        changeFormCaseByIdTabsDisableBtnStatus(state, status) {
            state.formCaseByIdTabsDisableBtnStatus = status;
        },
        changeFormCaseByIdTabPatientBtnStatus(state, status) {
            state.formCaseByIdTabPatientBtnStatus = status;
        },
        changeFormCaseByIdTabGeneralBtnStatus(state, status) {
            state.formCaseByIdTabGeneralBtnStatus = status;
        },
        changeFormCaseByIdTabMaterialBtnStatus(state, status) {
            state.formCaseByIdTabMaterialBtnStatus = status;
        },
        changeFormCaseByIdTabCustomerBtnStatus(state, status) {
            state.formCaseByIdTabCustomerBtnStatus = status;
        },
        changeFormCaseByIdTabDefectBtnStatus(state, status) {
            state.formCaseByIdTabDefectBtnStatus = status;
        },
        changeFormCaseByIdTabDiseaseBtnStatus(state, status) {
            state.formCaseByIdTabDiseaseBtnStatus = status;
        },
        changeFormCaseByIdTabCommentBtnStatus(state, status) {
            state.formCaseByIdTabCommentBtnStatus = status;
        },
        changeFormCaseByIdTabInformationBtnStatus(state, status) {
            state.formCaseByIdTabInformationBtnStatus = status;
        },
        changeFormCaseByIdTabReceptionCommentBtnStatus(state, status) {
            state.formCaseByIdTabReceptionCommentBtnStatus = status;
        },
        changeFormCaseByIdTabSampleCommentBtnStatus(state, status) {
            state.formCaseByIdTabSampleCommentBtnStatus = status;
        },
        changeFormCaseByIdTabReceptionInformationBtnStatus(state, status) {
            state.formCaseByIdTabReceptionInformationBtnStatus = status;
        },
        changeFormCaseByIdTabMacroDescriptionBtnStatus(state, status) {
            state.formCaseByIdTabMacroDescriptionBtnStatus = status;
        },
        changeFormCaseByIdTabMacroDescriptionPhotoBtnStatus(state, status) {
            state.formCaseByIdTabMacroDescriptionPhotoBtnStatus = status;
        },
        changeFormCaseByIdTabMacroDescriptionObjectBtnStatus(state, status) {
            state.formCaseByIdTabMacroDescriptionObjectBtnStatus = status;
        },
        changeFormCaseByIdTabMacroDescriptionConclusionBtnStatus(
            state,
            status
        ) {
            state.formCaseByIdTabMacroDescriptionConclusionBtnStatus = status;
        },
        changeFormCaseByIdTabMacroDescriptionCommentBtnStatus(state, status) {
            state.formCaseByIdTabMacroDescriptionCommentBtnStatus = status;
        },
        changeFormCaseByIdTabMacroDescriptionInformationBtnStatus(
            state,
            status
        ) {
            state.formCaseByIdTabMacroDescriptionInformationBtnStatus = status;
        },
        changeFormCaseByIdTabMicroDescriptionBtnStatus(state, status) {
            state.formCaseByIdTabMicroDescriptionBtnStatus = status;
        },
        changeFormCaseByIdTabMicroDescriptionMicroPreparationsBtnStatus(
            state,
            status
        ) {
            state.formCaseByIdTabMicroDescriptionMicroPreparationsBtnStatus =
                status;
        },
        changeFormCaseByIdTabMicroDescriptionConclusionBtnStatus(
            state,
            status
        ) {
            state.formCaseByIdTabMicroDescriptionConclusionBtnStatus = status;
        },
        changeFormCaseByIdTabMicroDescriptionServicesBtnStatus(state, status) {
            state.formCaseByIdTabMicroDescriptionServicesBtnStatus = status;
        },
        changeFormCaseByIdTabMicroDescriptionCommentBtnStatus(state, status) {
            state.formCaseByIdTabMicroDescriptionCommentBtnStatus = status;
        },
        changeFormCaseByIdTabMicroDescriptionResearchResponsibleBtnStatus(
            state,
            status
        ) {
            state.formCaseByIdTabMicroDescriptionResearchResponsibleBtnStatus =
                status;
        },
        changeFormCaseByIdTabMicroDescriptionInfoBtnStatus(state, status) {
            state.formCaseByIdTabMicroDescriptionInfoBtnStatus = status;
        },
        changeFormCaseByIdTabInformationFileUploaderBtnStatus(state, status) {
            state.formCaseByIdTabInformationFileUploaderBtnStatus = status;
        },
    },
    getters: {
        getFormCaseByIdTabsDisableBtnStatus(state) {
            return state.formCaseByIdTabsDisableBtnStatus;
        },
        getFormCaseByIdTabGeneralBtnStatus(state) {
            return state.formCaseByIdTabGeneralBtnStatus;
        },
        getFormCaseByIdTabPatientBtnStatus(state) {
            return state.formCaseByIdTabPatientBtnStatus;
        },
        getFormCaseByIdTabMaterialBtnStatus(state) {
            return state.formCaseByIdTabMaterialBtnStatus;
        },
        getFormCaseByIdTabCustomerBtnStatus(state) {
            return state.formCaseByIdTabCustomerBtnStatus;
        },
        getFormCaseByIdTabDefectBtnStatus(state) {
            return state.formCaseByIdTabDefectBtnStatus;
        },
        getFormCaseByIdTabDiseaseBtnStatus(state) {
            return state.formCaseByIdTabDiseaseBtnStatus;
        },
        getFormCaseByIdTabCommentBtnStatus(state) {
            return state.formCaseByIdTabCommentBtnStatus;
        },
        getFormCaseByIdTabInformationBtnStatus(state) {
            return state.formCaseByIdTabInformationBtnStatus;
        },
        getFormCaseByIdTabReceptionCommentBtnStatus(state) {
            return state.formCaseByIdTabReceptionCommentBtnStatus;
        },
        getFormCaseByIdTabSampleCommentBtnStatus(state) {
            return state.formCaseByIdTabSampleCommentBtnStatus;
        },
        getFormCaseByIdTabReceptionInformationBtnStatus(state) {
            return state.formCaseByIdTabReceptionInformationBtnStatus;
        },
        getFormCaseByIdTabMacroDescriptionBtnStatus(state) {
            return state.formCaseByIdTabMacroDescriptionBtnStatus;
        },
        getFormCaseByIdTabMacroDescriptionPhotoBtnStatus(state) {
            return state.formCaseByIdTabMacroDescriptionPhotoBtnStatus;
        },
        getFormCaseByIdTabMacroDescriptionObjectBtnStatus(state) {
            return state.formCaseByIdTabMacroDescriptionObjectBtnStatus;
        },
        getFormCaseByIdTabMacroDescriptionConclusionBtnStatus(state) {
            return state.formCaseByIdTabMacroDescriptionConclusionBtnStatus;
        },
        getFormCaseByIdTabMacroDescriptionCommentBtnStatus(state) {
            return state.formCaseByIdTabMacroDescriptionCommentBtnStatus;
        },
        getFormCaseByIdTabMacroDescriptionInformationBtnStatus(state) {
            return state.formCaseByIdTabMacroDescriptionInformationBtnStatus;
        },
        getFormCaseByIdTabMicroDescriptionBtnStatus(state) {
            return state.formCaseByIdTabMicroDescriptionBtnStatus;
        },
        getFormCaseByIdTabMicroDescriptionMicroPreparationsBtnStatus(state) {
            return state.formCaseByIdTabMicroDescriptionMicroPreparationsBtnStatus;
        },
        getFormCaseByIdTabMicroDescriptionConclusionBtnStatus(state) {
            return state.formCaseByIdTabMicroDescriptionConclusionBtnStatus;
        },
        getFormCaseByIdTabMicroDescriptionServicesBtnStatus(state) {
            return state.formCaseByIdTabMicroDescriptionServicesBtnStatus;
        },
        getFormCaseByIdTabMicroDescriptionCommentBtnStatus(state) {
            return state.formCaseByIdTabMicroDescriptionCommentBtnStatus;
        },
        getFormCaseByIdTabMicroDescriptionResearchResponsibleBtnStatus(state) {
            return state.formCaseByIdTabMicroDescriptionResearchResponsibleBtnStatus;
        },
        getFormCaseByIdTabMicroDescriptionInfoBtnStatus(state) {
            return state.formCaseByIdTabMicroDescriptionInfoBtnStatus;
        },
        getFormCaseByIdTabInformationFileUploaderBtnStatus(state) {
            return state.formCaseByIdTabInformationFileUploaderBtnStatus;
        },
    },
};
