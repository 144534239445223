import { api } from "@/api";

export default {
    state: {
        settingsReferencePatientPage: 1,
        settingsReferencePatientPageSize: 50,
        settingsReferencePatients: {},
        settingsReferencePatientsTotalPages: null,
        isSettingsReferencePatientsLoading: false,
        patientById: null,
        newPatient: null,
        selectedPreviousBiopsy: null,
    },
    actions: {
        async fetchSettingsReferencePatient({ dispatch, commit }, queryParams) {
            commit("updateSettingsReferencePatientsLoader", true);
            try {
                const token = await dispatch("getAccessToken");
                if (token) {
                    const patient = await api.getReferencePatientInfo(
                        queryParams,
                        token
                    );
                    commit("changeSettingsReferencePatients", patient.results);
                    commit(
                        "changeSettingsReferencePatientsTotalPages",
                        patient.total_pages
                    );
                    commit("updateSettingsReferencePatientsLoader", false);
                } else {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    commit("updateSettingsReferencePatientsLoader", false);
                    return;
                }
            } catch (error) {
                console.log(error);
            }
        },
        async fetchSettingsReferencePatientById({ dispatch, commit }, id) {
            commit("updateSettingsReferencePatientsLoader", true);
            try {
                const token = await dispatch("getAccessToken");
                if (token) {
                    const patient = await api.getReferencePatientById(
                        id,
                        token
                    );
                    commit("changeSettingsReferencePatientById", patient);
                    commit("updateSettingsReferencePatientsLoader", false);
                } else {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    commit("updateSettingsReferencePatientsLoader", false);
                    return;
                }
            } catch (error) {
                console.log(error);
            }
        },
        async fetchMoreSettingsReferencePatients(
            { dispatch, commit },
            queryParams
        ) {
            commit("updateSettingsReferencePatientsLoader", true);
            try {
                const token = await dispatch("getAccessToken");
                if (token) {
                    const patient = await api.getReferencePatientInfo(
                        queryParams,
                        token
                    );
                    commit(
                        "updateMoreSettingsReferencePatients",
                        patient.results
                    );
                    commit("updateSettingsReferencePatientsLoader", false);
                } else {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    commit("updateSettingsReferencePatientsLoader", false);
                }
            } catch (error) {
                console.log(error);
                commit("updateSettingsReferencePatientsLoader", false);
            }
        },
        async addSettingsReferencePatients({ dispatch, commit }, patient) {
            commit("updateSettingsReferencePatientsLoader", true);
            try {
                const token = await dispatch("getAccessToken");
                if (token) {
                    const patientData = await api.postReferencePatientInfo(
                        patient,
                        token
                    );
                    commit("addNewSettingsReferencePatient", patientData);
                    commit("updateSettingsReferencePatientsLoader", false);
                } else {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    commit("updateSettingsReferencePatientsLoader", false);
                    return;
                }
            } catch (error) {
                console.log(error);
            }
        },
        async editSettingsReferencePatients(
            { dispatch, commit, state },
            [patient, id]
        ) {
            commit("updateSettingsReferencePatientsLoader", true);
            try {
                const token = await dispatch("getAccessToken");
                if (token) {
                    await api.patchReferencePatientInfo(patient, id, token);
                    await dispatch(
                        "fetchSettingsReferencePatientById",
                        state.patientById.id
                    );
                } else {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    commit("updateSettingsReferencePatientsLoader", false);
                    return;
                }
            } catch (error) {
                commit("updateSettingsReferencePatientsLoader", false);
                console.log(error);
            }
        },
        deleteAddressFromPatient({ commit }, id) {
            commit("deleteAddress", id);
        },
        updatePatientAddress({ commit }, address) {
            commit("changePatientAddress", address);
        },
        addPatientLeagalGuardian({ commit }, legalGuardian) {
            commit("changeAddPatientLegalGuardian", legalGuardian);
        },
        updatePatientPreviousCases({ commit }, [previousCases, status]) {
            if (status === "add") {
                commit("addPatientPreviousCases", previousCases);
            } else if (status === "edit") {
                commit("editPatientPreviousCases", previousCases);
            } else if (status === "delete") {
                commit("deletePatientPreviousCases", previousCases);
            }
        },
        updateSelectedPreviousBiopsy({ commit }, previousBiopsyStudy) {
            commit("changeSelectedPreviousBiopsy", previousBiopsyStudy);
        },
        clearNewGlobalPatient({ commit }) {
            commit("clearNewGlobalPatient");
        },
    },
    mutations: {
        changeSettingsReferencePatients(state, settingsReferencePatients) {
            state.settingsReferencePatients = settingsReferencePatients;
        },
        updateMoreSettingsReferencePatients(state, settingsReferencePatients) {
            state.settingsReferencePatients = [
                ...state.settingsReferencePatients,
                ...settingsReferencePatients,
            ];
        },
        changeSettingsReferencePatientsTotalPages(
            state,
            settingsReferencePatientsTotalPages
        ) {
            state.settingsReferencePatientsTotalPages =
                settingsReferencePatientsTotalPages;
        },
        updateSettingsReferencePatientsLoader(state, condition) {
            state.isSettingsReferencePatientsLoading = condition;
        },
        addNewSettingsReferencePatient(state, patient) {
            state.newPatient = patient;
        },
        changeSettingsReferencePatientById(state, patient) {
            state.patientById = patient;
        },
        changePatientAddress(state, address) {
            state.patientById.address.push(address);
        },
        changeAddPatientLegalGuardian(state, legalGuardian) {
            state.patientById.legal_guardian.push(legalGuardian);
        },
        addPatientPreviousCases(state, previousCases) {
            state.patientById.previous_cases.push(previousCases);
        },
        editPatientPreviousCases(state, caseForEditing) {
            state.patientById.previous_cases =
                state.patientById.previous_cases.map((previousCase) => {
                    if (previousCase.id === caseForEditing.id) {
                        return caseForEditing;
                    } else {
                        return previousCase;
                    }
                });
        },
        deletePatientPreviousCases(state, id) {
            state.patientById.previous_cases =
                state.patientById.previous_cases.filter(
                    (previousCase) => previousCase.id !== id
                );
        },
        deleteAddress(state, id) {
            state.patientById.address = state.patientById.address.filter(
                (address) => address.id !== id
            );
        },
        changeSelectedPreviousBiopsy(state, previousBiopsyStudy) {
            state.selectedPreviousBiopsy = previousBiopsyStudy;
        },
        clearNewGlobalPatient(state) {
            state.newPatient = null;
        },
    },
    getters: {
        getSettingsReferencePatients(state) {
            return state.settingsReferencePatients;
        },
        getSettingsReferencePatientsTotalPages(state) {
            return state.settingsReferencePatientsTotalPages;
        },
        getSettingsReferencePatientsLoaderStatus(state) {
            return state.isSettingsReferencePatientsLoading;
        },
        getSettingsReferencePatientById(state) {
            return state.patientById;
        },
        getNewSettingsReferencePatient(state) {
            return state.newPatient;
        },
        getSelectedPreviousBiopsy(state) {
            return state.selectedPreviousBiopsy;
        },
    },
};
