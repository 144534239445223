<template>
    <div :id="dropdownId" class="dropdown__wrapper">
        <p v-if="dropdownLabel" class="field__label">
            {{ dropdownLabel }}
            <span v-if="isRequiredStyle" class="input__field_required">*</span>
        </p>
        <Dropdown
            v-model="selectedItem"
            :options="selectOptionList"
            :optionLabel="optionLabel"
            :placeholder="dropdownPlaceholder"
            :disabled="disableStatus"
            :pt="{
                list: { style: `max-width: ${dropdownWidth}px` },
            }"
            class="app_dropdown"
            :class="dropdownClasses"
            panelClass="dropdown__panel"
            :showClear="selectedItem !== null && selectedItem?.length !== 0"
            @click.stop
        >
            <!-- @click.stop используется для предотвращения открывания вкладки AccordionTab при клике на AppDropdown-->
            <template v-slot:dropdownicon>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                >
                    <path
                        d="M12.7074 5.23599C12.6146 5.14306 12.5043 5.06935 12.3829 5.01905C12.2615 4.96876 12.1315 4.94287 12.0001 4.94287C11.8687 4.94287 11.7386 4.96876 11.6173 5.01905C11.4959 5.06935 11.3856 5.14306 11.2928 5.23599L8.23542 8.29266C8.17291 8.35515 8.08814 8.39025 7.99975 8.39025C7.91136 8.39025 7.82659 8.35515 7.76408 8.29266L4.70742 5.23599C4.51991 5.04839 4.26556 4.94297 4.00032 4.94291C3.73508 4.94284 3.48068 5.04815 3.29308 5.23566C3.10549 5.42317 3.00006 5.67752 3 5.94276C2.99994 6.20799 3.10524 6.46239 3.29275 6.64999L6.35008 9.70732C6.56676 9.92402 6.82399 10.0959 7.1071 10.2132C7.39021 10.3305 7.69365 10.3908 8.00008 10.3908C8.30652 10.3908 8.60996 10.3305 8.89307 10.2132C9.17618 10.0959 9.43341 9.92402 9.65008 9.70732L12.7074 6.64999C12.8949 6.46246 13.0002 6.20816 13.0002 5.94299C13.0002 5.67783 12.8949 5.42352 12.7074 5.23599Z"
                        fill="#6A3F97"
                    />
                </svg>
            </template>
            <template #option="slotProps">
                <slot name="optionitem" :data="slotProps"></slot>
            </template>
            <template v-slot:clearicon>
                <div v-if="dontShowClearButton"></div>
                <svg
                    v-else
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    class="app_dropdown_clear"
                    @click.stop="onClearClick"
                >
                    <path
                        d="M12 3.99999C11.875 3.875 11.7054 3.80479 11.5287 3.80479C11.3519 3.80479 11.1824 3.875 11.0573 3.99999L8 7.05732L4.94267 3.99999C4.81765 3.875 4.64811 3.80479 4.47133 3.80479C4.29456 3.80479 4.12502 3.875 4 3.99999C3.87502 4.125 3.80481 4.29454 3.80481 4.47132C3.80481 4.64809 3.87502 4.81763 4 4.94265L7.05733 7.99999L4 11.0573C3.87502 11.1823 3.80481 11.3519 3.80481 11.5287C3.80481 11.7054 3.87502 11.875 4 12C4.12502 12.125 4.29456 12.1952 4.47133 12.1952C4.64811 12.1952 4.81765 12.125 4.94267 12L8 8.94265L11.0573 12C11.1824 12.125 11.3519 12.1952 11.5287 12.1952C11.7054 12.1952 11.875 12.125 12 12C12.125 11.875 12.1952 11.7054 12.1952 11.5287C12.1952 11.3519 12.125 11.1823 12 11.0573L8.94267 7.99999L12 4.94265C12.125 4.81763 12.1952 4.64809 12.1952 4.47132C12.1952 4.29454 12.125 4.125 12 3.99999Z"
                        fill="#6A3F97"
                    />
                </svg>
            </template>
        </Dropdown>
    </div>
</template>

<script>
export default {
    name: "AppDropdown",
    emits: ["updateDropdownItem"],
    props: {
        selectOptionList: [Array, Object],
        optionLabel: String,
        defaultValue: [Object, String],
        dropdownPlaceholder: String,
        dropdownClasses: Array,
        disableStatus: Boolean,
        clearDropdown: Boolean,
        dropdownLabel: String,
        isRequiredStyle: Boolean,
        dontShowClearButton: Boolean,
        dropdownId: String,
    },
    data() {
        return {
            selectedItem: null,
            dropdownWidth: null,
        };
    },
    methods: {
        onClearClick() {
            this.selectedItem = null;
        },
    },
    watch: {
        selectedItem(newValue) {
            this.$emit("updateDropdownItem", newValue);
        },
        clearDropdown(newValue) {
            if (newValue) {
                if (this.defaultValue) {
                    this.selectedItem = this.defaultValue;
                } else {
                    this.selectedItem = null;
                }
            }
        },
        defaultValue(newValue) {
            this.selectedItem = newValue;
        },
    },
    mounted() {
        if (this.dropdownId) {
            this.dropdownWidth = document.getElementById(
                this.dropdownId
            ).offsetWidth;
        }
        if (this.defaultValue) {
            this.selectedItem = this.defaultValue;
        }
    },
};
</script>

<style lang="sass">
@import "@/assets/css/_vars.sass"

.p-dropdown
    width: 100%
    height: 38px
    display: flex
    align-items: center
    padding-left: 16px
    font-family: "Roboto Flex", sans-serif
    font-size: 14px
    font-style: normal
    font-weight: 500
    line-height: 20px
    letter-spacing: -0.14px
    border: 1px solid $formInputBorderColor
    &:not(.p-disabled).p-focus
        box-shadow: none !important
        border: 1px solid $deepPurple !important
    .p-inputtext, .p-dropdown-label.p-placeholder
        font-family: "Roboto Flex", sans-serif
        font-size: 14px
        font-style: normal
        font-weight: 500
        line-height: 20px
        letter-spacing: -0.14px
        color: $textPrimaryBlack
    .p-dropdown-label.p-placeholder
        color: $placeholderColor
.p-disabled
    background: $gray !important
    border-radius: 4px
    .p-inputtext
        color: $dustyLavender !important
    .p-dropdown-trigger path
        fill: $dustyLavender !important
.dropdown__panel
    border-radius: 8px
    background: $white
    box-shadow: 0px 0px 8px 0px $whiteSmoke
    padding: 4px
    overflow-wrap: break-word
    z-index: 10000000 !important
    .p-dropdown-items .p-dropdown-item.p-highlight
        background: $whiteSmoke
        color: $royalPurple
    .p-dropdown-items .p-dropdown-item.p-dropdown-item
        font-family: "Roboto Flex", sans-serif
        font-size: 14px
        font-weight: 500
        line-height: 20px
        letter-spacing: -0.14px
        color: #1D0D2D
        padding: 12px 16px
        border-radius: 4px
        white-space: pre-wrap
        &:hover
            background: $whiteSmoke
            color: $mediumPurple
</style>
