<template>
    <Dialog
        v-model:visible="getLifetimeDeleteCaseDialogVisibility"
        modal
        header="Header"
        class="dialog"
        :closable="false"
        :pt="{
            headericons: {
                style: 'display: none;',
            },
        }"
    >
        <template #header>
            <div class="dialog__header">
                <span>Удаление случая(-ев)</span>
            </div>
        </template>
        <template #closeicon>
            <div style="display: none"></div>
        </template>
        <div class="dialog__body">
            <p>Вы собираетесь удалить случай!</p>
            <p>
                Ему будет проставлена отметка “Удалён” и дальнейшая работа с ним
                будет невозможна до его восстановления.
            </p>
            <p>Вы действительно хотите удалить случай?</p>
        </div>
        <template #footer>
            <Button
                class="button__purple_border"
                label="Нет"
                @click="closeDialog()"
            />
            <Button class="button__purple" label="Да" @click="acceptDelete()" />
        </template>
    </Dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
    name: "DeleteCasesDialog",
    computed: {
        ...mapGetters([
            "getLifetimeDeleteCaseDialogVisibility",
            "getLifetimeCasesChecked",
        ]),
    },
    methods: {
        ...mapActions([
            "updateLifetimeDeleteCaseDialogVisibility",
            "deleteLifetimeCases",
            "updateLifetimeCasesChecked"
        ]),
        closeDialog() {
            this.updateLifetimeDeleteCaseDialogVisibility(false);
        },
        async acceptDelete() {
            const casesIdsForDelete = {};
            casesIdsForDelete.ids = this.getLifetimeCasesChecked.map(
                (lifecase) => lifecase.id
            );
            this.deleteLifetimeCases(casesIdsForDelete).then(() => {
                this.updateLifetimeCasesChecked([]);
            });
            this.closeDialog();
        },
    },
};
</script>
