export default {
    state: {
        mortemPregnancyAndChildbirthBtnStatus: false,
    },
    actions: {
        updateMortemPregnancyAndChildbirthBtnStatus({ commit }, mortemPregnancyAndChildbirthBtnStatus) {
            commit("changeMortemPregnancyAndChildbirthBtnStatus", mortemPregnancyAndChildbirthBtnStatus);
        },
    },
    mutations: {
        changeMortemPregnancyAndChildbirthBtnStatus(state, mortemPregnancyAndChildbirthBtnStatus) {
            state.mortemPregnancyAndChildbirthBtnStatus = mortemPregnancyAndChildbirthBtnStatus;
        },
    },
    getters: {
        getMortemPregnancyAndChildbirthBtnStatus(state) {
            return state.mortemPregnancyAndChildbirthBtnStatus;
        }
    }
}