import { api } from '@/api'

export default {
    state: {
        patientIdentityDocuments: [],
        selectedIdentityDocument: null,
        isPatientDocumentsLoading: false,
    },
    actions: {
        async fetchPatientIdentityDocuments({ dispatch, commit }) {
            try {
                const token = await dispatch('getAccessToken')
                if (token) {
                    const documents = await api.getPatientIdentityDocuments(
                        token
                    )
                    commit('changePatientIdentityDocuments', documents)
                } else {
                    dispatch('getRedirectAuthAfterInvalidRefreshTokenAction')
                    return
                }
            } catch (error) {
                console.log(error)
            }
        },
        async addPatientIdentityDocuments(
            { dispatch, commit, rootGetters },
            data
        ) {
            commit('updateIsPatientDocumentsLoading', true)
            try {
                const token = await dispatch('getAccessToken')
                if (token) {
                    await api
                        .postPatientIdentityDocuments(data, token)
                        .then(() => {
                            dispatch(
                                'fetchSettingsReferencePatientById',
                                rootGetters.getSettingsReferencePatientById.id
                            )
                        })
                    commit('updateIsPatientDocumentsLoading', false)
                } else {
                    commit('updateIsPatientDocumentsLoading', false)
                    dispatch('getRedirectAuthAfterInvalidRefreshTokenAction')
                    return
                }
            } catch (error) {
                commit('updateIsPatientDocumentsLoading', false)
                console.log(error)
            }
        },
        async editPatientIdentityDocuments(
            { dispatch, commit, rootGetters },
            [data, id]
        ) {
            commit('updateIsPatientDocumentsLoading', true)
            try {
                const token = await dispatch('getAccessToken')
                if (token) {
                    await api
                        .patchPatientIdentityDocuments(id, data, token)
                        .then(() => {
                            dispatch(
                                'fetchSettingsReferencePatientById',
                                rootGetters.getSettingsReferencePatientById.id
                            )
                        })
                    commit('updateIsPatientDocumentsLoading', false)
                } else {
                    commit('updateIsPatientDocumentsLoading', false)
                    dispatch('getRedirectAuthAfterInvalidRefreshTokenAction')
                    return
                }
            } catch (error) {
                commit('updateIsPatientDocumentsLoading', false)
                console.log(error)
            }
        },
        async deletePatientIdentityDocuments({ dispatch }, [patientId, id]) {
            try {
                const token = await dispatch('getAccessToken')
                if (token) {
                    await api
                        .deletePatientIdentityDocuments(id, token)
                        .then(() => {
                            dispatch(
                                'fetchSettingsReferencePatientById',
                                patientId
                            )
                        })
                } else {
                    dispatch('getRedirectAuthAfterInvalidRefreshTokenAction')
                    return
                }
            } catch (error) {
                console.log(error)
            }
        },
        updateSelectedIdentityDocData({ commit }, data) {
            commit('changeSelectedIdentityDocData', data)
        },
    },
    mutations: {
        changePatientDocumentsLoadingStatus(state, status) {
            state.isPatientDocumentsLoading = status
        },
        changeSelectedIdentityDocData(state, data) {
            state.selectedIdentityDocument = data
        },
        updateIsPatientDocumentsLoading(state, data) {
            state.isPatientDocumentsLoading = data
        },
    },
    getters: {
        getSelectedIdentityDocData(state) {
            return state.selectedIdentityDocument
        },
        getIsPatientDocumentsLoading(state) {
            return state.isPatientDocumentsLoading
        },
    },
}
